import React, {Dispatch} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';

import {LightMainButton} from './../common/buttons/lightMineButton';
import Loader from 'js/pages/Loader';
import {QuestionDataInterface} from '../interfaces';
import {RootStateInterface} from '../reducers/rootReducer';
import {TextField} from '@material-ui/core';
import {connect} from 'react-redux';
import {forgotPassword} from '../actions/authActions';
import {getSecurityQuestionByUsername} from './../actions/questionActions';
import {logOut} from './../actions/authActions';
import styles from './styles/SecurityQuestion.module.scss';

// const securityQuestionIds = [501, 502, 503, 504, 505, 506, 507, 508, 509];

interface RecoveryPasswordProps extends RouteComponentProps<{slug: string}> {
    username: string | null;
    securityQuestions: QuestionDataInterface[];
    recoveryPasswordHash: string | null;
    recoveryPasswordHashSuccess: boolean | null;
    getSecurityQuestionByUsername: Function;
    getForgotPasswordHash: Function;
    logOut: Function;
}

export class RecoveryPassword extends React.Component<
    RecoveryPasswordProps,
    {
        value: string;
        questionId: number;
        errorMessage: string;
        questionDropdownActive: boolean;
    }
> {
    private securityQuestionLabel: string;
    constructor(props: RecoveryPasswordProps) {
        super(props);

        this.state = {
            value: '',
            questionId: 0,
            errorMessage: '',
            questionDropdownActive: false,
        };

        if (!this.props.username) {
            this.props.history.push(`/${this.props.match.params.slug}`);
        }

        this.securityQuestionLabel = '';
    }

    componentDidMount() {
        if (this.props.username) {
            this.props.getSecurityQuestionByUsername();   
        } else {
            this.props.history.push(`/${this.props.match.params.slug}`);
        }
    }

    componentWillReceiveProps(nextProps: RecoveryPasswordProps) {
        if (!this.props.securityQuestions.length && nextProps.securityQuestions.length) {
            this.securityQuestionLabel = nextProps.securityQuestions[0].textEn;
            this.handleChangeQuestion(null, nextProps.securityQuestions[0].id);
        }
    }

    componentDidUpdate() {
        if (this.props.recoveryPasswordHash) {
            this.props.history.push(`/${this.props.match.params.slug}/reset-password`);
        } else if (this.props.recoveryPasswordHashSuccess === false && !this.state.errorMessage) {
            this.setState({errorMessage: 'The answer is wrong. Try again or create a new account.'});
        }
    }

    sendAnswer = () => {
        if (this.isValidAnswer()) {
            this.props.getForgotPasswordHash(this.state.questionId, this.state.value, this.props.username);
            this.setState({errorMessage: ''});
        } else {
            this.setState({errorMessage: 'Your answer must be minimum 2 characters, no special symbols allowed.'});
        }
    };

    createNewAccount = () => {
        this.props.history.push(`/${this.props.match.params.slug}/register/username`);
    };

    isValidAnswer(): boolean {
        const {value, questionId} = this.state;
        return value.length >= 2 && value.length <= 100 && /^[\w\d ]+$/.test(value) && questionId > 0;
    }

    handleChangeQuestion = (event: React.MouseEvent | null, questionId: number) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            questionId: questionId,
            questionDropdownActive: false,
        });
    };

    onClickBack = () => {
        this.props.history.push(`/${this.props.match.params.slug}/login/username`);
    };

    onClickChooseQuestion = () => {
        this.setState({questionDropdownActive: !this.state.questionDropdownActive});
    };

    renderSecurityQuestionDropdown = () => {
        if (this.state.questionDropdownActive) {
            return (
                <div className={styles.containerOptions}>
                    {this.props.securityQuestions.map((securityQuestion) => (
                        <div
                            key={securityQuestion.id}
                            className={styles.option}
                            onClick={(e: React.MouseEvent) => {
                                this.securityQuestionLabel = securityQuestion.textEn;
                                this.handleChangeQuestion(e, securityQuestion.id);
                            }}
                            tabIndex={0}
                            aria-pressed="false"
                            aria-label={`${securityQuestion.textEn}`}
                            role="button"
                            onKeyUp={(e) =>
                                onKeyUp(e, () => {
                                    this.securityQuestionLabel = securityQuestion.textEn;
                                    this.handleChangeQuestion(null, securityQuestion.id);
                                })
                            }
                            onKeyDown={(e) =>
                                onKeyDown(e, () => {
                                    this.securityQuestionLabel = securityQuestion.textEn;
                                    this.handleChangeQuestion(null, securityQuestion.id);
                                })
                            }
                        >
                            {securityQuestion.textEn}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    render() {
        if (!this.props.securityQuestions.length) {
            return <Loader />;
        }

        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <div
                        className={styles.back}
                        tabIndex={0}
                        role="button"
                        onClick={this.onClickBack}
                        onKeyDown={(e) => onKeyDown(e, this.onClickBack)}
                        onKeyUp={(e) => onKeyUp(e, this.onClickBack)}
                    >
                        Back
                    </div>
                    <div className={styles.title}>Hi, {this.props.username && this.props.username.split("+")[0]}!</div>
                    <div className={styles.description}>
                        Please, answer security questions to get access to your account:
                    </div>
                    <div
                        className={`${styles.inputDescription} ${
                            this.state.questionDropdownActive ? styles.securityDropdownActive : ''
                        }`}
                    >
                        <div
                            className={`${styles.recoveryPasswordDropdownContainer}`}
                            onClick={this.onClickChooseQuestion}
                            onKeyUp={(e) => onKeyUp(e, () => this.setState({questionDropdownActive: true}))}
                            onKeyDown={(e) => onKeyDown(e, () => this.setState({questionDropdownActive: true}))}
                        >
                            <TextField
                                id="standard-basic"
                                type="text"
                                multiline
                                className={styles.inputContainer}
                                InputProps={{
                                    readOnly: true,
                                }}
                                rowsMax={4}
                                value={this.securityQuestionLabel}
                                label={'Choose your question'}
                            />
                        </div>
                    </div>
                    <div
                        className={`${styles.inputContainer} ${
                            this.state.errorMessage ? styles.inputContainerError : ''
                        }`}
                    >
                        <TextField
                            type="text"
                            className={styles.inputContainer}
                            onChange={(e) => this.setState({value: e.target.value})}
                            id="standard-basic"
                            value={this.state.value}
                            label={'Enter your answer'}
                            autoComplete="off"
                        />
                        {this.state.errorMessage && (
                            <img
                                className={styles.errorImg}
                                src={require('./../../images/icons/error-outline.svg')}
                                alt="error"
                            />
                        )}
                        <div className={styles.errorMessage}>{this.state.errorMessage}</div>
                    </div>
                    <div className={styles.containerButton}>
                        <LightMainButton
                            isDefaultActive={this.isValidAnswer() ? true : false}
                            onButtonClick={this.sendAnswer}
                            text="Continue"
                        />
                        <div
                            className={styles.newAccountLink}
                            tabIndex={0}
                            role="button"
                            onClick={this.createNewAccount}
                            onKeyUp={(e) => onKeyUp(e, this.createNewAccount)}
                            onKeyDown={(e) => onKeyDown(e, this.createNewAccount)}
                        >
                            Create new account
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const RecoveryPasswordWithRouter = withRouter(RecoveryPassword);

const mapStateToProps = (state: RootStateInterface) => ({
    username: state.auth.username,
    recoveryPasswordHash: state.auth.recoveryPasswordHash,
    recoveryPasswordHashSuccess: state.auth.recoveryPasswordHashSuccess,
    securityQuestions: state.questions.securityQuestions,
});

const mapDispatchToProps = (dispatch: Dispatch<{type: string}>) => ({
    getSecurityQuestionByUsername: () => {
        return dispatch(getSecurityQuestionByUsername());
    },
    getForgotPasswordHash: (questionId: number, answer: string, username: string) => {
        return dispatch(forgotPassword({questionId, answer, username}));
    },
    logOut() {
        return dispatch(logOut());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPasswordWithRouter);
