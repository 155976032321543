import React, {Dispatch} from 'react';
import styles from './styles/RegisterAuth.module.scss';
import Password from './../../components/auth/Password';
import {connect} from 'react-redux';
import {clearErrors, registerUser, loginUser} from './../../actions/authActions';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {RootStateInterface} from '../../reducers/rootReducer';
import {persistor} from '../../store';
interface RegisterPasswordPropsInterface extends RouteComponentProps<{slug: string}> {
    regUser: Function;
    schoolId: string | null;
    error: string | null;
    registerSuccess: boolean | null;
    loginSuccess: boolean | null;
    username: string | null;
    clearErrors: Function;
    loginUser: Function;
}

export class RegisterPassword extends React.Component<RegisterPasswordPropsInterface, {}> {
    private password: number | null;
    constructor(props: RegisterPasswordPropsInterface) {
        super(props);

        this.password = null;
    }

    componentDidMount() {
        if (!this.props.username) {
            this.props.history.push(`/${this.props.match.params.slug}/hello`);
        }
    }
    async componentDidUpdate(prevProps: RegisterPasswordPropsInterface) {
        if (this.props.registerSuccess) {
            try {
                await persistor.purge();
            } catch (e) {
                console.error(e);
            }
            this.props.loginUser({type: 'student', username: this.props.username, password: this.password});
        }
        if (this.props.loginSuccess) {
            this.props.history.push(`/${this.props.match.params.slug}/security-question`);
        }
    }

    protected saveRegisterPassword = (creds: {password: string}) => {
        if (this.props.regUser) {
            this.password = Number(creds.password);
            this.props.regUser({
                username: this.props.username,
                password: Number(creds.password),
                termsAccepted: true,
                schoolId: this.props.schoolId,
            });
        }
    };

    render() {
        return (
            <div className={`${styles.container} ${styles.containerCustomize}`}>
                <div className={styles.containerPassword}>
                    <Password
                        isReg={true}
                        username={this.props?.username && this.props.username.split("+")[0]}
                        disabled={'ontouchstart' in window}
                        navigateToPrevStep={() =>
                            this.props.history.push(`/${this.props.match.params.slug}/register/username`)
                        }
                        saveLoginPassword={this.saveRegisterPassword}
                        error={this.props.error}
                        clearErrors={this.props.clearErrors}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        registerSuccess: state.auth.registerSuccess,
        loginSuccess: state.auth.success,
        username: state.auth.username,
        schoolId: state.survey.schoolId,
        error: state.auth.registerErrorMessage,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<{type: string; username?: string; password?: Number; termsAccepted?: boolean; schoolId?: string}>
) => ({
    regUser(params: {type: string; username: string; password: number; termsAccepted: boolean; schoolId: string}) {
        return dispatch(registerUser(params));
    },
    loginUser(params: {username: string; password: number}) {
        return dispatch(loginUser(params));
    },
    clearErrors() {
        return dispatch(clearErrors());
    },
});

const RegisterPasswordWithRouter = withRouter(RegisterPassword);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPasswordWithRouter);
