import React from 'react';
import styles from './styles/Loader.module.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

export class Loader extends React.Component<{}, {}> {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.containerProgressbar}>
                    <LinearProgress />
                </div>
            </div>
        );
    }
}

export default Loader;
