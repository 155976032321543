import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import styles from './styles/Journey.module.scss';
import {BubblesInterface} from '../interfaces';
import {setBubbleNumber, setQuestionsMode} from './../actions/questionActions';
import BurgerMenu from './../components/BurgerMenu';
import {formatTime} from '../helpers/formatDate';
import {RootStateInterface} from '../reducers/rootReducer';
import {JourneyBubble} from '../components/journey/JourneyBubble';
import Loader from 'js/pages/Loader';

interface JourneyPropsInterface
    extends RouteComponentProps<{
        slug?: string;
    }> {
    morningBubbles: BubblesInterface;
    afternoonBubbles: BubblesInterface;
    questionsMode: string;
    bubbleNumber: number;
    afternoonStartDate: string | null;
    showResultsDate: string | null;
    demography: {[key: string]: string};
    setBubbleNumber: Function;
    setQuestionsMode: Function;
}

interface ClassesTypes {
    container: string;
    dots: string;
}

const classes: {[key: number]: ClassesTypes} = {
    0: {
        container: `${styles.bubble} ${styles.bubble1}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsOne}`,
    },
    1: {
        container: `${styles.bubble} ${styles.bubble2}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsTwo}`,
    },
    2: {
        container: `${styles.bubble} ${styles.bubble3}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsThree}`,
    },
    3: {
        container: `${styles.bubble} ${styles.bubble4}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsFour}`,
    },
    4: {
        container: `${styles.bubble} ${styles.bubble5}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsFive}`,
    },
    5: {
        container: `${styles.bubble} ${styles.bubble6}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsSix}`,
    },
    6: {
        container: `${styles.bubble} ${styles.bubble7}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsSeven}`,
    },
    7: {
        container: `${styles.bubble} ${styles.bubble8}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsEight}`,
    },
    8: {
        container: `${styles.bubble} ${styles.bubble3}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsThree}`,
    },
    9: {
        container: `${styles.bubble} ${styles.bubble4}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsFour}`,
    },
    10: {
        container: `${styles.bubble} ${styles.bubble5}`,
        dots: `${styles.bubbleDots} ${styles.bubbleDotsFive}`,
    },
    11: {
        container: `${styles.bubble} ${styles.bubble6}`,
        dots: '',
    },
};

export class Journey extends React.Component<JourneyPropsInterface, {}> {
    private intervalId: any;

    componentDidMount() {
        if (this.props.afternoonStartDate) {
            this.intervalId = setInterval(this.switchToAfternoonQuestions, 1000 * 5);
            this.switchToAfternoonQuestions();
        }
    }

    componentDidUpdate() {
        if (this.props.afternoonStartDate && !this.intervalId) {
            this.intervalId = setInterval(this.switchToAfternoonQuestions, 1000 * 5);
            this.switchToAfternoonQuestions();
        }
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    switchToAfternoonQuestions = () => {
        if (this.isMorningBubblesDone(this.props.morningBubbles) && this.isAfternoonTime()) {
            this.props.setQuestionsMode('afternoonQuestions', this.props);
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        }
    };

    isMorningBubblesDone(morningBubbles: BubblesInterface) {
        const bubblesCount = Object.keys(morningBubbles).length;
        return (
            this.props.questionsMode === 'morningQuestions' &&
            this.getBubblesDone(morningBubbles) === bubblesCount &&
            bubblesCount > 0
        );
    }

    checkShowResult() {
        const doneBubblesCount = this.getBubblesDone();
        const bubbles = this.getBubbles();
        if (
            (this.props.questionsMode === 'afternoonQuestions' &&
                doneBubblesCount === Object.keys(bubbles).length &&
                doneBubblesCount > 0) ||
            (this.props.showResultsDate && new Date(this.props.showResultsDate) <= new Date())
        ) {
            this.props.history.push(`/${this.props.match.params.slug}/success-finished`);
        }
    }

    isAfternoonTime() {
        if (!this.props.afternoonStartDate) {
            return false;
        }

        return new Date(this.props.afternoonStartDate) < new Date();
    }

    renderTitle() {
        const bubbles = this.getBubbles();
        if (!bubbles[0]) {
            return '';
        }
        const doneBubblesCount = this.getBubblesDone();
        if (this.props.questionsMode === 'afternoonQuestions' && !bubbles[0].done) {
            return 'Let’s continue!';
        }
        if (this.props.questionsMode === 'morningQuestions' && !bubbles[0].done) {
            return 'Let’s start our journey!';
        }

        if (doneBubblesCount === Object.keys(bubbles).length && doneBubblesCount > 0) {
            return (
                <div>
                    <div className={styles.yourProgressTitle}>Your Progress</div>100% complete
                </div>
            );
        } else {
            return (
                <div>
                    <div className={styles.yourProgressTitle}>Your Progress</div>
                    {this.getComplete(doneBubblesCount, bubbles)} complete
                </div>
            );
        }
    }

    getBubblesDone(morningBubbles?: BubblesInterface) {
        const bubbles = morningBubbles || this.getBubbles();
        let doneBubblesCount = 0;
        for (const key in bubbles) {
            doneBubblesCount += bubbles[key].done ? 1 : 0;
        }
        return doneBubblesCount;
    }

    getCompleted(doneBubblesCount: number, bubbles: BubblesInterface): number {
        const bubblesCount = Object.keys(bubbles).length;
        return Math.round((doneBubblesCount / bubblesCount) * 100);
    }

    getComplete(doneBubblesCount: number, bubbles: BubblesInterface): string {
        return `${Math.round(this.getCompleted(doneBubblesCount, bubbles))}%`;
    }

    renderHeader() {
        const bubbles = this.getBubbles();
        if (!bubbles[0]) {
            return '';
        }
        const doneBubblesCount = this.getBubblesDone();
        if (this.props.questionsMode === 'afternoonQuestions' && !bubbles[0].done) {
            return 'Welcome to part two of the Walkthrough!';
        }
        if (this.props.questionsMode === 'morningQuestions' && !bubbles[0].done) {
            return 'Click on part two to get started.';
        }
        if (doneBubblesCount < Object.keys(bubbles).length) {
            return 'Continue through the sections to make sure your voice is heard.';
        }
        if (
            this.props.questionsMode === 'morningQuestions' &&
            doneBubblesCount === Object.keys(bubbles).length &&
            doneBubblesCount > 0
        ) {
            return 'Part one is complete! Part two will be available later today.';
        }
        if (
            this.props.questionsMode === 'afternoonQuestions' &&
            doneBubblesCount === Object.keys(bubbles).length &&
            doneBubblesCount > 0
        ) {
            const resultTime: string = this.props.showResultsDate ? formatTime(this.props.showResultsDate) : '';
            return `You're all done! Results will be available once everyone has completed the survey, no later than ${resultTime} today.`;
        }
    }

    getBubbles(): BubblesInterface {
        let bubbles: BubblesInterface;
        if (this.props.questionsMode === 'morningQuestions') {
            bubbles = this.props.morningBubbles;
        } else {
            bubbles = this.props.afternoonBubbles;
        }
        return bubbles;
    }

    isDemographyDone() {
        return Object.keys(this.props.demography).length > 0;
    }

    handleBubbleClick = (bubbleNumber: number) => {
        const {questionsMode} = this.props;
        let currentBubbleNumber = bubbleNumber;
        if (questionsMode === 'morningQuestions') {
            currentBubbleNumber--;
        }
        const bubbles: BubblesInterface = this.getBubbles();
        let isActive = false;
        let isDemographyBubble = false;
        if (questionsMode === 'morningQuestions') {
            isActive =
                (bubbleNumber === 0 && this.isDemographyDone()) || (bubbleNumber > 0 && bubbles[bubbleNumber - 1].done);
            isDemographyBubble = bubbleNumber === 0 ? true : false;
        } else {
            isActive = bubbles[bubbleNumber].done;
        }

        if (
            isActive ||
            currentBubbleNumber === 0 ||
            (bubbleNumber === 0 && questionsMode === 'morningQuestions') ||
            (bubbles[currentBubbleNumber - 1].done && !bubbles[currentBubbleNumber].done)
        ) {
            if (!isDemographyBubble) {
                this.props.setBubbleNumber(currentBubbleNumber, this.props.questionsMode);
            }
            const slug = isDemographyBubble ? 'demographical' : this.props.questionsMode;
            this.props.history.push(`/${this.props.match.params.slug}/journey/${slug}`);
        }
    };

    render() {

        this.checkShowResult();
        if (Object.keys(this.props.morningBubbles).length === 0 ) {
            return <Loader />;
        }

        const bubbles: BubblesInterface = this.getBubbles();
        const doneBubblesCount: number = this.getBubblesDone();
        let BubblesList: string[] | number[] = Object.keys(bubbles);
        BubblesList = BubblesList.map((value: string) => parseInt(value, 10));
        // Morning questions have one additional bubble for demographic questions
        if (this.props.questionsMode === 'morningQuestions') {
            BubblesList.push(BubblesList.length);
        }

        return (
            <div className={styles.container}>
                <div className={styles.fixedPanel}>
                    <div className={styles.containerTitle}>
                        <div className={styles.titleSubcontainer}>
                            <div className={styles.burgerContainer}>
                                <BurgerMenu defaultColorMode={true} />
                            </div>
                            <div className={styles.title}>{this.renderTitle()}</div>
                        </div>
                    </div>
                    <div className={styles.containerProgressBar}>
                        <div
                            className={styles.progress}
                            style={{width: `${this.getCompleted(doneBubblesCount, bubbles)}%`}}
                        ></div>
                        <div className={styles.progressBar}>
                            <div>
                                <div className={styles.oval}></div>
                            </div>
                            <div>
                                <div className={styles.oval}></div>
                            </div>
                            <div>
                                <div className={styles.oval}></div>
                            </div>
                            <div>
                                <div className={styles.oval}></div>
                            </div>
                            <div>
                                <div className={styles.oval}></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.clickTitle}>{this.renderHeader()}</div>
                </div>
                <div className={styles.subContainerBubbles}>
                    <div className={`${styles.bubblesList} clear_fix`}>
                        {BubblesList.map((bubbleNumber: number) => {
                            const {questionsMode} = this.props;
                            let isDone = false;
                            if (questionsMode === 'morningQuestions') {
                                isDone =
                                    (bubbleNumber === 0 && this.isDemographyDone()) ||
                                    (bubbleNumber > 0 && bubbles[bubbleNumber - 1] && bubbles[bubbleNumber - 1].done);
                            } else {
                                isDone = bubbles[bubbleNumber] && bubbles[bubbleNumber].done;
                            }

                            return (
                                <div
                                    className={`${classes[bubbleNumber].container} ${
                                        isDone ? styles.bubbleContainerActive : ''
                                    }`}
                                    key={bubbleNumber}
                                >
                                    <JourneyBubble
                                        index={bubbleNumber}
                                        isDone={isDone}
                                        onClick={this.handleBubbleClick}
                                    />
                                    {bubbleNumber + 1 < BubblesList.length ? (
                                        <div className={classes[bubbleNumber].dots}></div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<{
        type: string;
        number?: number;
        mode?: 'morningQuestions' | 'afternoonQuestions';
    }>
) => ({
    setBubbleNumber(number: number, mode: 'morningQuestions' | 'afternoonQuestions') {
        return dispatch(setBubbleNumber(number, mode));
    },
    setQuestionsMode(mode: 'morningQuestions' | 'afternoonQuestions') {
        return dispatch(setQuestionsMode(mode));
    },
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        morningBubbles: state.surveyTemplate.morningBubbles,
        afternoonBubbles: state.surveyTemplate.afternoonBubbles,
        questionsMode: state.config.questionsMode,
        bubbleNumber: state.config.bubbleNumber,
        afternoonStartDate: state.survey.afternoonStartDate,
        showResultsDate: state.survey.showResultsDate,
        demography: state.demographicQuestions.answers,
    };
};

const JourneyWithRouter = withRouter(Journey);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyWithRouter);
