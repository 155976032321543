import axios from 'axios';

// works without access token
export function fetchSurveyTermsData(params: {slug: string}) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/school-surveys/${params.slug}/terms`);
}

export function fetchSurveyTermsAnywayData(params: {slug: string}) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/school-surveys/${params.slug}/findAnyway`);
}


export function fetchSurvey(params: {id: string}) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/school-surveys/${params.id}`);
}
