import axios from 'axios';

export function getPinnedQuestions(dimension: string, schoolSurveyId: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/pinned-questions/${dimension}/${schoolSurveyId}`);
}

export function updatePinnedQuestions(dimension: string, schoolSurveyId: string, pinnedQuestions: number[]) {
    return axios.put(`${process.env.REACT_APP_API_HOST}/pinned-questions`, {
        dimension,
        schoolSurveyId,
        pinnedQuestions,
    });
}
