import React from 'react';
import {CheckboxInput} from '../../common/inputs/checkboxInput';
import {MainButton} from '../../common/buttons/mainButton';
import {TextInput} from '../../common/inputs/textInput';
import styles from './styles/RegisterUsername.module.scss';

type QuestionFieldInterface = {
    value: string;
    error: string | null;
};

type QuestionFieldsInterface = {
    phone: QuestionFieldInterface;
    brothers: QuestionFieldInterface;
    middleName: QuestionFieldInterface;
};

interface RegisterUsernameStateInterface {
    username: string | null;
    agreedTerms: boolean;
    errors: null | {
        username: Array<string>;
    };
    fields: QuestionFieldsInterface;
    isFieldsValid: boolean;
}

interface RegisterUsernamePropsInterface {
    username: string | null;
    errors: null | {
        username: Array<string>;
    };
    saveRegisterUsername: Function;
    clearUserExists: Function;
}

export default class RegisterUsername extends React.Component<
    RegisterUsernamePropsInterface,
    RegisterUsernameStateInterface
> {
    constructor(props: RegisterUsernamePropsInterface) {
        super(props);

        this.state = {
            username: this.props.username && this.props.username.split('+')[0],
            errors: this.props.errors,
            agreedTerms: false,
            fields: {
                phone: {
                    value: '',
                    error: null,
                },
                brothers: {
                    value: '',
                    error: null,
                },
                middleName: {
                    value: '',
                    error: null,
                },
            },
            isFieldsValid: false,
        };
    }

    private fieldSetAndValidation = (
        name: keyof QuestionFieldsInterface,
        value: string
    ): QuestionFieldInterface | undefined => {
        const {fields} = this.state;
        const field: QuestionFieldInterface | undefined = fields[name];

        if (!field) return undefined;

        // Clear Error
        field.error = null;

        // Check fields
        switch (name) {
            case 'phone':
                field.value = value;
                if (value.length !== 2) field.error = 'Min length is 2';
                if (!value.length) field.error = 'Phone is required';
                break;
            case 'brothers':
                field.value = value;
                if (!value.length) field.error = 'Brothers count is required';
                break;
            case 'middleName':
                if (value.length < 2) field.error = 'Min length is 2';
                if (!value.length) field.error = 'Phone is required';
                /\d/.test(value) ? (field.error = 'Non numeric values') : (field.value = value);
                break;
        }

        // Active confirm button
        let isFieldsValid = !field.error;

        for (let {error, value} of Object.values(fields)) {
            if (error || !value) {
                isFieldsValid = false;
            }
        }

        this.setState({isFieldsValid});

        return field;
    };

    private onInputChange = ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
        if (value.length <= 2) {
            this.props.clearUserExists();
            this.fieldSetAndValidation(name as keyof QuestionFieldsInterface, value);
            this.setState({fields: this.state.fields});
        }
    };

    private submitForm = () => {
        if (!this.state.errors && this.state.agreedTerms) {
            const {
                fields: {phone, brothers, middleName},
            } = this.state;
            const username = `${phone.value}${brothers.value}${middleName.value}`.toLowerCase();

            this.setState({username}, () => this.props.saveRegisterUsername({username}));
        }
    };

    private termsText = () => {
        return (
            <div>
                I agree with{' '}
                <span
                    className={styles.underline}
                    onClick={() => {
                        window.open('https://inspiredstudents.org/terms-of-use/', '_blank');
                    }}
                >
                    Terms & Privacy
                </span>
            </div>
        );
    };

    render() {
        const {
            fields: {phone, brothers, middleName},
            isFieldsValid,
            agreedTerms,
        } = this.state;
        const {errors} = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.block}>
                    <div className={styles.title}>Welcome!</div>
                    <div className={styles.containerInput}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.submitForm();
                            }}
                        >
                            <TextInput
                                id="registerPhone"
                                type="number"
                                name="phone"
                                label="The last 2 digits of your phone number"
                                helperText="numbers only"
                                inputProps={{maxLength: 2}}
                                containerClassName={styles.customInputContainer}
                                inputClassName={styles.customInputField}
                                value={phone.value}
                                onInputChange={this.onInputChange}
                                altCorrect="You have entered correct phone"
                                altIncorrect="You have entered incorrect phone"
                                error={phone.error}
                                required
                            />

                            <TextInput
                                id="registerBrothers"
                                type="number"
                                name="brothers"
                                label="How many brothers do you have?"
                                helperText="numbers only"
                                containerClassName={styles.customInputContainer}
                                inputClassName={styles.customInputField}
                                value={brothers.value}
                                onInputChange={this.onInputChange}
                                altCorrect="You have entered correct brothers"
                                altIncorrect="You have entered incorrect brothers"
                                error={brothers.error}
                                required
                            />

                            <TextInput
                                id="registerMiddleName"
                                type="text"
                                name="middleName"
                                label="The first two letters of your middle name:"
                                helperText="characters only"
                                containerClassName={styles.customInputContainer}
                                inputClassName={styles.customInputField}
                                value={middleName.value}
                                onInputChange={this.onInputChange}
                                altCorrect="You have entered correct middleName"
                                altIncorrect="You have entered incorrect middleName"
                                error={middleName.error}
                                required
                            />
                        </form>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.checkboxContainer}>
                        <CheckboxInput
                            text={this.termsText()}
                            checked={agreedTerms}
                            onCheckboxChange={() => this.setState({agreedTerms: !agreedTerms})}
                        />
                    </div>

                    {!!errors?.username[0] && <div className={styles.text}>{errors.username[0]}</div>}

                    <div className={styles.continueButtonContainer}>
                        <MainButton
                            text="Continue"
                            isActive={!this.state.errors && agreedTerms && isFieldsValid}
                            onButtonClick={this.submitForm}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
