import React from 'react';
import styles from './styles/NotFound.module.scss';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {LightMainButton} from './../common/buttons/lightMineButton';
import {connect} from 'react-redux';
import {RootStateInterface} from '../reducers/rootReducer';
import {MainButton} from './../common/buttons/mainButton';

interface NotFoundProps extends RouteComponentProps<{slug: string}> {
    darkmode?: boolean;
}

export class NotFound extends React.Component<NotFoundProps, {}> {
    private render404Image = () => {
        if (this.props.darkmode) {
            return require('./../../images/404-white.svg');
        }

        return require('./../../images/404-mobile.svg');
    };

    private renderButton = () => {
        if (this.props.darkmode) {
            return (
                <MainButton
                    onButtonClick={() => (window.location.href = 'https://inspiredstudents.org/')}
                    isDefaultActive={true}
                    text="Back to InspirED"
                />
            );
        }

        return (
            <LightMainButton
                onButtonClick={() => (window.location.href = 'https://inspiredstudents.org/')}
                isDefaultActive={true}
                text="Back to InspirED"
            />
        );
    };

    render() {
        return (
            <div className={`${styles.container} ${this.props.darkmode ? styles.containerDark : ''}`}>
                <div className={styles.imgContainer}>
                    <img alt="Page not found" src={this.render404Image()} />
                </div>
                <div className={styles.title}>Oops...</div>
                <div className={styles.subtitle}>
                    Something went wrong and we
                    <br /> did not find the URL you
                    <br /> requested.
                </div>
                <div className={styles.buttonContainer}>{this.renderButton()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateInterface) => ({
    darkmode: state.config.darkmode,
});

const NotFoundWithRouter = withRouter(NotFound);

export default connect(mapStateToProps, null)(NotFoundWithRouter);
