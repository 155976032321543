import {REQUEST_SURVEY_TEMPLATE, SAVE_BUBBLES} from '../constants/actionTypes';

export function getSurveyTemplate(params: {surveyTemplateId: string}) {
    return {
        type: REQUEST_SURVEY_TEMPLATE,
        ...params,
    };
}

export function saveBubbles() {
    return {
        type: SAVE_BUBBLES,
    };
}
