import {LOAD_RESULTS, LOAD_DIMENSION_OBSERVATION_RESULTS, LOGOUT_USER} from '../../constants/actionTypes';
import {ResultInterface} from '../../interfaces';

interface ResultElementInterface {
    key: string;
    value: string;
    metadata?: {
        isMeaningfulDifference: boolean;
    };
}

interface ActionInterface {
    type: string;
    result: ResultElementInterface[];
    message: string;
}

interface ResultStateInterface {
    requestInProgress: boolean;
    result: ResultInterface;
    message: string;
}

const initialState: ResultStateInterface = {
    requestInProgress: false,
    result: {},
    message: '',
};

export default function resultReducer(state = initialState, action: ActionInterface): ResultStateInterface {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case LOAD_RESULTS:
        case LOAD_DIMENSION_OBSERVATION_RESULTS:
            return {
                ...state,
                requestInProgress: true,
            };
        case `${LOAD_RESULTS}_SUCCESS`:
            return {
                ...state,
                requestInProgress: false,
                result: {
                    ...state.result,
                    ...action.result.reduce((acc: ResultInterface, element: ResultElementInterface) => {
                        acc[element.key] = {
                            value: element.value,
                            isMeaningfulDifference: element.metadata?.isMeaningfulDifference,
                        };
                        return acc;
                    }, {}),
                },
            };
        case `${LOAD_RESULTS}_ERROR`:
            return {
                ...state,
                requestInProgress: false,
                result: {},
                message: action.message,
            };
        default:
            return state;
    }
}
