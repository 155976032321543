import React from 'react';
import styles from './styles/demographicalRadioInput.module.scss';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';

interface DemographicalRadioInputProps {
    text?: string;
    value?: string;
    id?: string;
    onChange: Function;
}

export class DemographicalRadioInput extends React.Component<DemographicalRadioInputProps, {}> {
    onSelect = () => {
        this.props.onChange(this.props.id);
    };

    render() {
        return (
            <div key={this.props.id} className={styles.container}>
                <div className={styles.containerInput}>
                    <input
                        type="checkbox"
                        onChange={this.onSelect}
                        className={styles.checkbox}
                        checked={this.props.value === this.props.id}
                        id={this.props.id}
                        role="radio"
                        aria-labelledby={`${this.props.id}_item`}
                        aria-checked={this.props.value === this.props.id}
                    />
                    <label htmlFor={this.props.id} className={styles.labelCheckbox}>
                        <div className={styles.bgWhite}>
                            <div className={styles.checkedDiv}></div>
                        </div>
                    </label>
                </div>
                <div 
                    className={styles.containerText} 
                    onClick={this.onSelect}
                    onKeyDown={(e) => onKeyDown(e, this.onSelect)}
                    onKeyUp={(e) => onKeyUp(e, this.onSelect)}
                    id={`${this.props.id}_item`}
                    tabIndex={0}
                >
                    <div>{this.props.text}</div>
                </div>
            </div>
        );
    }
}
