import axios from 'axios';
import {BubblesInterface} from '../../interfaces';

export function fetchStudent(params: {id: string}) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/students/${params.id}`);
}

export function saveBubbles(params: {
    studentId: string;
    bubbles: {morningBubbles: BubblesInterface; afternoonBubbles: BubblesInterface};
}) {
    return axios.put(`${process.env.REACT_APP_API_HOST}/students/${params.studentId}`, {
        bubbles: params.bubbles,
    });
}

export function saveSecurityQuestion(params: {studentId: string; answer: string; questionId: number}) {
    return axios.put(`${process.env.REACT_APP_API_HOST}/students/${params.studentId}`, {
        securityQuestionId: params.questionId,
        securityQuestionAnswer: params.answer,
    });
}
