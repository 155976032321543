import {
    REQUEST_PINNED_QUESTIONS,
    REQUEST_QUESTIONS,
    REQUEST_QUESTION_BY_USERNAME,
    SAVE_SECURITY_QUESTION,
    SET_BUBBLE_DONE,
    SET_BUBBLE_NUMBER,
    SET_QUESTIONS_MODE,
    SET_QUESTIONS_NUMBER,
    SKIP_QUESTIONS_NUMBER,
    UPDATE_PINNED_QUESTIONS
} from './../constants/actionTypes';

export function getQuestions(questionType: string, ids: number[]) {
    return {
        type: REQUEST_QUESTIONS,
        ids,
        questionType,
    };
}

export function getSecurityQuestionByUsername() {
    return {
        type: REQUEST_QUESTION_BY_USERNAME
    }; 
}

export function setQuestionsMode(mode: 'morningQuestions' | 'afternoonQuestions') {
    return {
        type: SET_QUESTIONS_MODE,
        mode,
    };
}

export function setQuestionNumber(number: number) {
    return {
        type: SET_QUESTIONS_NUMBER,
        number,
    };
}

export function setBubbleNumber(number: number, mode: 'morningQuestions' | 'afternoonQuestions') {
    return {
        type: SET_BUBBLE_NUMBER,
        number,
        mode,
    };
}

export function setBubbleDone(number: number, mode: 'morningBubbles' | 'afternoonBubbles') {
    return {
        type: SET_BUBBLE_DONE,
        number,
        mode,
    };
}

export function skipQuestionNumber(number: number) {
    return {
        type: SKIP_QUESTIONS_NUMBER,
        number,
    };
}

export function saveSecurityQuestion(questionId: number, answer: string) {
    return {
        type: SAVE_SECURITY_QUESTION,
        questionId,
        answer,
    };
}

export function getPinnedQuestions(dimension: string, schoolSurveyId: string) {
    return {
        type: REQUEST_PINNED_QUESTIONS,
        dimension,
        schoolSurveyId,
    };
}

export function updatePinnedQuestions(dimension: string, schoolSurveyId: string, pinnedQuestions: number[]) {
    return {
        type: UPDATE_PINNED_QUESTIONS,
        dimension,
        schoolSurveyId,
        pinnedQuestions,
    };
}
