import {combineReducers} from 'redux';
import authReducer from './auth/authReducer';
import surveyReducer from './survey/surveyReducer';
import demographicQuestionsReducer from './questions/demographicQuestionsReducer';
import questionsReducer from './questions/questionsReducer';
import surveyTemplateReducer from './surveyTemplate/surveyTemplateReducer';
import configReducer from './config/configReducer';
import answerReducer from './answer/answerReducer';
import resultReducer from './result/resultReducer';
import windowReducer from './window/windowReducer';
import pinnedQuestionReducer from './questions/pinnedQuestionsReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    survey: surveyReducer,
    demographicQuestions: demographicQuestionsReducer,
    questions: questionsReducer,
    pinnedQuestions: pinnedQuestionReducer,
    answers: answerReducer,
    surveyTemplate: surveyTemplateReducer,
    config: configReducer,
    result: resultReducer,
    window: windowReducer,
});

export type RootState = typeof rootReducer;
export type RootStateInterface = ReturnType<typeof rootReducer>;
export default rootReducer;
