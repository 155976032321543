import {Button} from '@material-ui/core';
import React from 'react';
import styles from './styles/arrowButton.module.scss';
import {spaceKeyCode} from '../../constants/keyboard';

interface ArrowButtonPropsInterface {
    text: string;
    onButtonClick: Function;
}

export class ArrowButton extends React.PureComponent<ArrowButtonPropsInterface, {}> {
    private onButtonClick = () => {
        this.props.onButtonClick();
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // The action button is activated by space on the keyup event, but the
        // default action for space is already triggered on keydown. It needs to be
        // prevented to stop scrolling the page before activating the button.
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.onButtonClick();
        }
        
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.onButtonClick();
        }
    }

    render() {
        return (
            <div 
                className={styles.welcomeButtonContainer} 
                onClick={this.onButtonClick}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                role="button" 
                tabIndex={0} 
            >
                <div className={styles.welcomeButtonTitle}>{this.props.text}</div>
                <Button variant="contained" aria-label={this.props.text} className={styles.welcomeButton} />
            </div>
        );
    }
}
