import React from 'react';
import styles from './styles/Password.module.scss';
import {TextInput} from './../../common/inputs/textInput';
import {MainButton} from './../../common/buttons/mainButton';
import {Back} from './../../common/navigation/back';
import {MIN_IPAD_WIDTH, MIN_IPAD_HEIGHT} from './../../constants/dimensions';
import {connect} from 'react-redux';
import {RootStateInterface} from '../../reducers/rootReducer';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';

interface PasswordPropsInterface {
    error: string | null;
    disabled?: boolean | undefined;
    saveLoginPassword: Function;
    navigateToPrevStep?: Function;
    navigateToRecoveryPassword?: Function;
    clearErrors?: Function;
    isReg?: boolean;
    width: number;
    height: number;
    username?: string | null;
}

interface PasswordStateInterface {
    inputType: string;
    password: string;
    errors: null | {
        password: Array<string>;
    };
}

interface PasswordInterface {
    password: {
        presence: {
            message: string;
        };
        length: {
            minimum: Number;
            maximum: Number;
            tooShort: string;
            tooLong: string;
        };
        format: {
            pattern: string;
            message: string;
        };
    };
}

export class Password extends React.Component<PasswordPropsInterface, PasswordStateInterface> {
    private constrains: PasswordInterface;
    constructor(props: PasswordPropsInterface) {
        super(props);

        this.state = {
            inputType: 'password',
            password: '',
            errors: null,
        };

        this.constrains = {
            password: {
                presence: {
                    message: 'is required',
                },

                format: {
                    pattern: '[0-9]+',
                    message: 'cannot contain words or special characters',
                },

                length: {
                    minimum: 4,
                    maximum: 4,
                    tooShort: 'needs to have 4 numbers',
                    tooLong: 'needs to have 4 numbers',
                },
            },
        };
    }

    componentWillUnmount() {
        if (this.props.clearErrors) {
            this.props.clearErrors();
        }
    }

    componentWillUpdate() {
        if (this.props.error && this.state.password.length < 4) {
            if (this.props.clearErrors) {
                this.props.clearErrors();
            }
        }
    }

    componentWillReceiveProps(nextProps: PasswordPropsInterface) {
        if (this.state.password.length === 4 && nextProps.error) {
            this.setState({
                password: '',
            });
        }
    }

    private onButtonClick = (e: React.MouseEvent, number: Number) => {
        if (e) {
            e.preventDefault()
        }
        const password = this.state.password + number;
        if (this.state.password.length < 4) {
            this.setState({
                password: password,
            });
        } else {
            this.setState({
                password: `${number}`,
                errors: null,
            });
        }

        if (password.length === 4) {
            this.setState({errors: null});
        }
    };

    protected changeInputType = () => {
        if (this.state.inputType === 'number') {
            this.setState({
                inputType: 'password',
            });
        } else {
            this.setState({
                inputType: 'number',
            });
        }
    };

    protected onPasswordSave = () => {
        if (this.state.password.length === 4) {
            this.props.saveLoginPassword({password: this.state.password});
        }
    };

    private errorMessage = () => {
        if (this.state.errors) {
            return this.state.errors.password[0];
        } else if (this.props.error) {
            return this.props.error;
        }

        return null;
    };

    private passwordTitle = () => {
        const {isReg} = this.props;

        return (
            <div className={styles.titleText}>
                Please, {isReg ? 'create' : 'enter'} your <br />
                4-numbers password:
            </div>
        );
    };

    private passwordTitleIpad = () => {
        const {isReg} = this.props;

        return (
            <div>
                Please, {isReg ? 'create' : 'enter'} <br />
                4-digit <br />
                password:
            </div>
        );
    };

    protected onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!isNaN(+e.target.value)) {
            if (this.state.password.length < 4) {
                this.setState({
                    password: e.target.value,
                });
            } else {
                this.setState({
                    password: '',
                });
            }
        }
    };

    protected navigateToPrevStep = () => {
        if (this.props.navigateToPrevStep) {
            this.props.navigateToPrevStep();
        }

        if (this.props.clearErrors) {
            this.props.clearErrors();
            this.setState({
                errors: null,
            });
        }
    };

    private renderIpadView = () => {
        const that = this;
        return (
            <div className={styles.container}>
                {this.props.navigateToPrevStep && (
                    <div className={styles.backContainer}>
                        <Back onNavigationClick={this.navigateToPrevStep} />
                    </div>
                )}
	            {this.props.username && (
		            <p className={styles.generatedUsername}>
			            You generated username is "<span>{this.props.username}</span>"
		            </p>
	            )}
                <div className={styles.ipadPasswordContainer}>
                    <div className={styles.inputContainer}>
                        <div className={styles.ipadPasswordTitle}>{this.passwordTitleIpad()}</div>
                            <div className={styles.passwordInputContainer}>
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    this.onPasswordSave()
                                }}>
                                    <TextInput
                                        disabled={this.props.disabled}
                                        type={this.state.inputType}
                                        label="Enter password..."
                                        value={this.state.password}
                                        onInputChange={this.onInputChange}
                                        altCorrect="You have entered correct password"
                                        altIncorrect="You have entered incorrect password"
                                        error={this.errorMessage()}
                                        errorIcon={true}
                                        onIconClick={this.changeInputType}
                                        inputProps={{
                                            pattern: "[0-9]*",
	                                          inputMode: "numeric"
                                        }}
                                    />
                                </form>
                            </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        {Array.apply(0, Array(9)).map(function (x, number) {
                            const actualNumber = number + 1;
                            return (
                                <div
                                    onClick={(e) => that.onButtonClick(e, actualNumber)}
                                    className={styles.number}
                                    key={actualNumber}
                                >
                                    <span>{actualNumber}</span>
                                </div>
                            );
                        })}
                        <div></div>
                        <div onClick={(e) => that.onButtonClick(e, 0)} className={styles.number}>
                            0
                        </div>
                        <div onClick={() => this.setState({password: ''})}>
                            <img src={require('./../../../images/icons/del.svg')} alt="Reset password input" />
                        </div>
                    </div>
                </div>
                <div className={styles.saveButtonContainer}>
                    <MainButton
                        text="Save"
                        isActive={this.state.password.length === 4}
                        onButtonClick={this.onPasswordSave}
                    />
                    {this.props.navigateToRecoveryPassword && (
                        <div
                            className={styles.forgotPassword}
                            tabIndex={0}
                            role="button"
                            onClick={() => this.props.navigateToRecoveryPassword!()}
                            onKeyDown={(e) => onKeyDown(e, () => this.props.navigateToRecoveryPassword!())}
                            onKeyUp={(e) => onKeyUp(e, () => this.props.navigateToRecoveryPassword!())}
                        >
                            Forgot password?
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const that = this;
        if (this.props.width >= MIN_IPAD_WIDTH && this.props.height >= MIN_IPAD_HEIGHT) {
            return this.renderIpadView();
        }

        return (
            <div className={styles.container}>
                {this.props.navigateToPrevStep && (
                    <div className={styles.backContainer}>
                        <Back onNavigationClick={this.navigateToPrevStep} />
                    </div>
                )}
		            {this.props.username && (
			            <p className={styles.generatedUsername}>
				            You generated username is "<span>{this.props.username}</span>"
			            </p>
		            )}
                <div className={styles.titleContainer}>{this.passwordTitle()}</div>
                <div className={styles.buttonContainer}>
                    {Array.apply(0, Array(9)).map(function (x, number) {
                        const actualNumber = number + 1;
                        return (
                            <div
                                onClick={(e) => that.onButtonClick(e, actualNumber)}
                                className={styles.number}
                                key={actualNumber}
                            >
                                <span>{actualNumber}</span>
                            </div>
                        );
                    })}
                    <div></div>
                    <div onClick={(e) => that.onButtonClick(e, 0)} className={styles.number}>
                        0
                    </div>
                    <div onClick={() => this.setState({password: ''})}>
                        <img src={require('./../../../images/icons/del.svg')} alt="Reset password input" />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        this.onPasswordSave()
                    }}>
                        <TextInput
                            type={this.state.inputType}
                            label="Enter password..."
                            value={this.state.password}
                            altCorrect="You have entered correct password"
                            altIncorrect="You have entered incorrect password"
                            error={this.errorMessage()}
                            errorIcon={true}
                            onInputChange={this.onInputChange}
                            onIconClick={this.changeInputType}
                            inputProps={{
                                pattern: "[0-9]*",
                                inputmode: "numeric"
                            }}
                        />
                    </form>
                </div>
                <div className={styles.saveButtonContainer}>
                    <MainButton
                        text={this.props.isReg ? 'Save' : 'Log In'}
                        isActive={this.state.password.length === 4}
                        onButtonClick={this.onPasswordSave}
                    />
                    {this.props.navigateToRecoveryPassword && (
                        <div className={styles.forgotPassword} onClick={() => this.props.navigateToRecoveryPassword!()}>
                            Forgot password?
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        width: state.window.width,
        height: state.window.height,
    };
};

export default connect(mapStateToProps)(Password);
