import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ContainerJourney from './containers/ContainerJourney';
import Container from './containers/Container';
import Welcome from './pages/Welcome';
import Loader from 'js/pages/Loader';

import Journey from './pages/Journey';
import ContainerDemographical from './containers/ContainerDemographical';

import Hello from './pages/Hello';
import Terms from './pages/Terms';
import LoginUsername from './pages/auth/LoginUsername';
import LoginPassword from './pages/auth/LoginPassword';
import RegisterUsername from './pages/auth/RegisterUsername';
import RegisterPassword from './pages/auth/RegisterPassword';

import PublicDevice from './pages/PublicDevice';
import SuccessFinished from './pages/SuccessFinished';
import HandleIt from './pages/HandleIt';
import RecoveryPassword from './pages/RecoveryPassword';
import ResetPassword from './pages/ResetPassword';
import LoggedUserTerms from './pages/LoggedUserTerms';

const ContainerMorningQuestions = lazy(() => import('./containers/ContainerMorningQuestions'));
const ContainerAfternoonQuestions = lazy(() => import('./containers/ContainerAfternoonQuestions'));
const SecurityQuestion = lazy(() => import('./pages/SecurityQuestion'));
const ContainerResults = lazy(() => import('./containers/ContainerResults'));
const ResultDetail = lazy(() => import('./containers/ResultDetail'));
const ResultObservation = lazy(() => import('./containers/ResultObservation'));
const NotFound = lazy(() => import('./pages/NotFound'));
const ResetStudentPassword = lazy(() => import('./containers/ResetStudentPassword'));
const SetStudentPassword = lazy(() => import('./pages/SetStudentPassword'));

export default class Routes extends React.Component {
    render() {
        return (
            <Router>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route exact path="/reset" component={ResetStudentPassword} />
                        <Route exact path="/student/set-password" component={SetStudentPassword} />
                        <Route exact path="/student/security-question" component={SecurityQuestion} />
                        <Route exact path="/not-found" component={NotFound} />
                        <Route path="/:slug">
                            <Container>
                                <Switch>
                                <Route exact path="/:slug" component={Welcome} />
                                <Route exact path="/:slug/hello" component={Hello} />
                                <Route exact path="/:slug/terms" component={Terms} />
                                <Route exact path="/:slug/register/username" component={RegisterUsername} />
                                <Route exact path="/:slug/register/password" component={RegisterPassword} />
                                <Route exact path="/:slug/security-question" component={SecurityQuestion} />
                                <Route exact path="/:slug/public-device" component={PublicDevice} />
                                <Route exact path="/:slug/login/username" component={LoginUsername} />
                                <Route exact path="/:slug/login/password" component={LoginPassword} />
                                <Route exact path="/:slug/recovery-password" component={RecoveryPassword} />
                                <Route exact path="/:slug/reset-password" component={ResetPassword} />
                                <Route path="/:slug/journey">
                                    <ContainerJourney>
                                        <Switch>
                                        <Route exact path="/:slug/journey" component={Journey} />
                                        <Route
                                            exact
                                            path="/:slug/journey/demographical"
                                            component={ContainerDemographical}
                                        />
                                        <Route
                                            exact
                                            path="/:slug/journey/morningquestions"
                                            component={ContainerMorningQuestions}
                                        />
                                        <Route
                                            exact
                                            path="/:slug/journey/afternoonquestions"
                                            component={ContainerAfternoonQuestions}
                                        />
                                        <Route exact path="/:slug/journey/handle-it" component={HandleIt} />
                                        <Route path="*" component={NotFound} />
                                        </Switch>
                                    </ContainerJourney>
                                </Route>
                                <Route exact path="/:slug/terms-info" component={LoggedUserTerms} />
                                <Route exact path="/:slug/results" component={ContainerResults} />
                                <Route exact path="/:slug/result-detail/:dimension" component={ResultDetail} />
                                <Route
                                    exact
                                    path="/:slug/result-observation/:dimension"
                                    component={ResultObservation}
                                />
                                <Route exact path="/:slug/success-finished" component={SuccessFinished} />
                                <Route path="/:slug/404" component={NotFound} />
                                <Route path="*" component={NotFound} />
                                </Switch>
                            </Container>
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}
