import {all, fork} from 'redux-saga/effects';
import {
    setLoginUsernameSaga,
    registerUserSaga,
    logOutSaga,
    loginUsingAccessToken,
    forgotPasswordSaga,
    resetPasswordSaga,
    setUsernameListener
} from './authSaga';
import {setDemographicQuestions} from './demographicSaga';
import {getQuestions, getSecurityQuestionByUsername} from './questionSaga';
import {getSurveyTemplate} from './surveyTemplateSaga';
import {saveAnswerSaga, saveSubQuestionsAnswerSaga} from './answerSaga';
import {getSurveyTermsDataSaga, watchLoadSurveyData, watchLoadSurveyResultData, watchLoadStudent, getSurveyTermsDataAnywaySaga} from './surveySaga';
import {saveBubblesSaga, saveSecurityQuestionSaga} from './studentSaga';
import {getResultsSaga, getDimensionResultsSaga, getDimensionObservationResultsSaga} from './resultSaga';
import {getPinnedQuestionsSaga, updatePinnedQuestionsSaga} from './pinnedQuestionSaga';

export default function* rootSaga() {
    yield all([
        fork(getSurveyTermsDataSaga),
        fork(setLoginUsernameSaga),
        fork(registerUserSaga),
        fork(setDemographicQuestions),
        fork(logOutSaga),
        fork(getQuestions),
        fork(getSurveyTemplate),
        fork(saveAnswerSaga),
        fork(saveSubQuestionsAnswerSaga),
        fork(saveBubblesSaga),
        fork(saveSecurityQuestionSaga),
        fork(watchLoadSurveyData),
        fork(watchLoadSurveyResultData),
        fork(watchLoadStudent),
        fork(loginUsingAccessToken),
        fork(getResultsSaga),
        fork(getDimensionResultsSaga),
        fork(getDimensionObservationResultsSaga),
        fork(forgotPasswordSaga),
        fork(resetPasswordSaga),
        fork(getPinnedQuestionsSaga),
        fork(updatePinnedQuestionsSaga),
        fork(getSecurityQuestionByUsername),
        fork(setUsernameListener),
        fork(getSurveyTermsDataAnywaySaga)
    ]);
}
