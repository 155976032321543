import axios from 'axios';

export function fetchAnswers(schoolSurveyId: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/answers?schoolSurveyId=${schoolSurveyId}`);
}

export function saveAnswer(params: any) {
    return axios.post(`${process.env.REACT_APP_API_HOST}/answers`, {
        questionId: params.questionId,
        value: params.value,
        skipReason: params.skipReason,
        schoolSurveyId: params.schoolSurveyId,
    });
}

export function updateAnswer(params: any) {
    return axios.put(`${process.env.REACT_APP_API_HOST}/answers/${params.id}`, {
        value: params.value,
        skipReason: params.skipReason,
    });
}
