import {SAVE_ANSWER, LOAD_ANSWERS, LOGOUT_USER} from './../../constants/actionTypes';

interface AnswerInterface {
    id: string;
    value: string;
    questionId: number;
}

interface AnswerStateInterface {
    requestInProgress: boolean;
    answer: {[key: number]: AnswerInterface};
    message?: string;
}

interface ActionInterface extends AnswerInterface {
    type: string;
    message?: string;
    answers?: AnswerInterface[];
}

const initialState: AnswerStateInterface = {
    requestInProgress: false,
    answer: {},
    message: '',
};

export default function (state = initialState, action: ActionInterface): AnswerStateInterface {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case SAVE_ANSWER:
            return {
                ...state,
                requestInProgress: true,
            };
        case `${SAVE_ANSWER}_SUCCESS`:
            const {type, ...answer} = action;
            return {
                ...state,
                requestInProgress: false,
                answer: {
                    ...state.answer,
                    [answer.questionId]: answer,
                },
            };
        case `${SAVE_ANSWER}_ERROR`:
            return {
                ...state,
                requestInProgress: false,
                message: action.message,
            };
        case `${LOAD_ANSWERS}_SUCCESS`:
            const {answers = []} = action;
            const initialValue: {[key: number]: AnswerInterface} = {};
            return {
                ...state,
                requestInProgress: false,
                answer: {
                    ...state.answer,
                    ...answers.reduce((accumulator, answer: AnswerInterface) => {
                        accumulator[answer.questionId] = answer;
                        return accumulator;
                    }, initialValue),
                },
            };
        default:
            return state;
    }
}
