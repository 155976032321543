import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import styles from './styles/SuccessFinished.module.scss';
import BurgerMenu from './../components/BurgerMenu';
import {RootStateInterface} from '../reducers/rootReducer';
import {getSurveyResultData} from '../actions/surveyActions';
import {loadResult} from '../actions/resultActions';
import {formatTime} from '../helpers/formatDate';
import {ResultInterface} from '../interfaces';
import {LightMainButton} from './../common/buttons/lightMineButton';

interface SuccessFinishedProps extends RouteComponentProps<{slug: string}> {
    showResultsDate: string | null;
    getSurveyResultData: Function;
    result: ResultInterface;
    loadResult: Function;
}

export class SuccessFinished extends React.Component<SuccessFinishedProps> {
    private intervalId: any;
    private userRole: String | null
    constructor (props: SuccessFinishedProps) {
        super (props)
        this.userRole = localStorage.getItem('role')
    }

    componentDidMount() {
        if (!this.props.showResultsDate) {
            this.props.getSurveyResultData();
        } else if (!this.intervalId) {
            this.checkResults();
        }
    }

    componentDidUpdate() {
        if (!this.intervalId) {
            this.checkResults();
        }
    }

    checkResults() {
        this.intervalId = setInterval(() => {
            this.props.loadResult();
            if (this.isResultReady()) {
                clearInterval(this.intervalId);
            }
        }, 1000 * 60);
        this.props.loadResult();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    isResultReady() {
        return (
            this.props.showResultsDate &&
            new Date(this.props.showResultsDate) <= new Date() &&
            this.props.result['survey.cnt_participants']
        );
    }

    formatResultTime() {
        if (this.props.showResultsDate) {
            return formatTime(this.props.showResultsDate);
        }

        return null;
    }

    getResult = () => {
        if (this.isResultReady()) {
            this.props.history.push(`/${this.props.match.params.slug}/results`);
        }
    };

    renderResultsPlaceholder = () => {
        return (
            <div className={styles.contentText}>
                {this.renderReadyPlaceholder()}
            </div>
        )
    }

    renderReadyPlaceholder = () => {
        if (this.isResultReady()) {
            return (
                <span>
                    Now you can see the result and compare
                    <br />
                    the answers with the answers of others.
                </span>
            )
        } else {
            if (this.userRole !== 'SUPER_ADMIN' && this.userRole !== 'SCHOOL_ADMIN') {
                return (
                    <span>
                        Results will be available once everyone <br />
                        has completed the survey, no later than <br />
                        {this.formatResultTime()} today.
                    </span>
                )
            } else {
                return (
                    <span>
                        Results is not yet calculated. <br />
                        <br />
                        You can handle result calculations in Admin Panel.
                    </span>
                )
            }
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <div className={styles.header}>
                        <BurgerMenu showHomeMenu={false} defaultColorMode={true} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            {this.isResultReady() ? (
                                <span>
                                    Your school climate <br />
                                    score is calculated!
                                </span>
                            ) : (
                                <span>
                                    Your school climate <br />
                                    score is being <br />
                                    calculated!
                                </span>
                            )}
                        </div>
                        <div
                            className={`${styles.skate} ${
                                this.isResultReady() ? styles.readyResultSkate : styles.noReadyResultsSkate
                            }`}
                        ></div>
                        {this.renderResultsPlaceholder()}
                    </div>
                    <div className={`${styles.containerButton}`}>
                        <LightMainButton
                            isDefaultActive={this.isResultReady() ? true : false}
                            onButtonClick={this.getResult}
                            text="Get Results"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const SuccessFinishedWithRouter = withRouter(SuccessFinished);

const mapStateToProps = function (state: RootStateInterface) {
    return {
        showResultsDate: state.survey.showResultsDate,
        result: state.result.result,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<{
        type: string;
    }>
) => ({
    getSurveyResultData() {
        return dispatch(getSurveyResultData());
    },
    loadResult() {
        return dispatch(loadResult());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessFinishedWithRouter);
