import React from 'react';
import styles from './styles/LoggedUserTerms.module.scss';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {RootStateInterface} from '../reducers/rootReducer';
import {connect} from 'react-redux';
import {LightMainButton} from './../common/buttons/lightMineButton';

interface LoggedUserTermsProps extends RouteComponentProps<{slug: string}> {
    terms: string;
}

export class LoggedUserTerms extends React.Component<LoggedUserTermsProps, {}> {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <div className={styles.title}>Informed Assent</div>
                        <div
                            className={styles.termsContainer}
                            dangerouslySetInnerHTML={{__html: this.props.terms ? this.props.terms : ''}}
                        ></div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <div className={styles.buttonSubcontainer}>
                            <LightMainButton
                                isDefaultActive={true}
                                onButtonClick={() =>
                                    this.props.history.push(`/${this.props.match.params.slug}/journey`)
                                }
                                text="Got it!"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        terms: state.survey.terms,
    };
};

const LoggedUserTermsWithRouter = withRouter(LoggedUserTerms);

export default connect(mapStateToProps, null)(LoggedUserTermsWithRouter);
