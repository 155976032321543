import axios from 'axios';

export function fetchQuestions(ids: number[], questionType: string,) {
    if (questionType === 'securityQuestions') {
        return axios.get(`${process.env.REACT_APP_API_HOST}/questions/securityQuestions`);
    } else {
        const query = ids.map((id) => `id=${id}`).join('&');
        return axios.get(`${process.env.REACT_APP_API_HOST}/questions?${query}`);
    }
}

export function fetchQuestionByUsername(username: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/questions/securityQuestion/${username}`);
}