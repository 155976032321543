import React, {Dispatch} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {clearUserExists, setUsername} from './../../actions/authActions';

import {Back} from './../../common/navigation/back';
import RegisterLoginUsername from './../../components/auth/RegisterUsername';
import {RootStateInterface} from '../../reducers/rootReducer';
import {connect} from 'react-redux';
import styles from './styles/RegisterAuth.module.scss';

interface RegisterUsernamePropsInterface extends RouteComponentProps<{slug: string}> {
    setUsername: Function;
    schoolId: string | null;
    error?: string;
    success: boolean;
    navigateTo?: Function;
    clearErrors: Function;
    username: string | null;
    hasUser?: boolean | null;
    clearUserExists: Function;
}

export class RegisterUsername extends React.Component<RegisterUsernamePropsInterface, {}> {
    componentWillReceiveProps (nextProps: RegisterUsernamePropsInterface) {
        if (this.props.username && nextProps.hasUser === false) {
            this.props.history.push(`/${this.props.match.params.slug}/register/password`);
        }
    }

    protected setUsername = (creds: {username: string}) => {
        this.props.setUsername({username: `${creds.username}+${this.props.schoolId}`});
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <div className={styles.containerRegisterUsername}>
                        <div className={styles.containerLoginBackButton}>
                            <Back
                                onNavigationClick={() => {
                                    this.props.setUsername({username: ''});
                                    this.props.history.push(`/${this.props.match.params.slug}/terms`);
                                }}
                            />
                        </div>
                        <RegisterLoginUsername
                            errors={this.props.hasUser ? {
                                username: ['This username already exists'] } : null
                            }
                            clearUserExists={this.props.clearUserExists}
                            username={this.props.username}
                            saveRegisterUsername={this.setUsername} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<{type: string; username?: string}>) => ({
    setUsername(params: {type?: string; username: string}) {
        return dispatch(setUsername(params));
    },
    clearUserExists(params: {type?: string}) {
        return dispatch(clearUserExists())
    }
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        username: state.auth.username,
        hasUser: state.auth.hasUser,
        schoolId: state.survey.schoolId,
    };
};

const RegisterUsernameWithRouter = withRouter(RegisterUsername);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUsernameWithRouter);
