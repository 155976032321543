import axios from 'axios';

export function fetchUser(params: {username: string; password: Number; from: string; surveyId: string}) {
    return axios.post(`${process.env.REACT_APP_API_HOST}/auth/login`, {
        username: params.username,
        password: String(params.password),
        from: params.from,
        surveyId: params.surveyId,
    });
}

export function registerUser(params: {username: string; password: Number; termsAccepted: boolean; schoolId: string}) {
    return axios.post(`${process.env.REACT_APP_API_HOST}/students`, {
        username: params.username,
        password: String(params.password),
        termsAccepted: params.termsAccepted,
        schoolId: params.schoolId,
    });
}

export function logOut() {
    return axios.post(`${process.env.REACT_APP_API_HOST}/auth/logout`, {
        refreshToken: localStorage.getItem('refreshToken'),
    });
}

export function getRefreshedToken() {
    return axios.post(`${process.env.REACT_APP_API_HOST}/auth/refresh`, {
        refreshToken: localStorage.getItem('refreshToken'),
    });
}

export function forgotPassword(params: {questionId: number; answer: string; username: string}) {
    return axios.post(`${process.env.REACT_APP_API_HOST}/auth/forgot-student-password`, {
        username: params.username,
        securityQuestionId: params.questionId,
        securityQuestionAnswer: params.answer,
    });
}

export function resetPassword(params: {hash: string; newPassword: string}) {
    return axios.post(`${process.env.REACT_APP_API_HOST}/auth/reset-student-password`, {
        hash: params.hash,
        newPassword: params.newPassword,
    });
}

export function userExist(params: {username: string}) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/auth/user-exists/${params.username}`)
}