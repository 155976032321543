import React from 'react';
import styles from './styles/Login.module.scss';
import {TextInput} from './../../common/inputs/textInput';
import {MainButton} from './../../common/buttons/mainButton';
import AbstractLogin from './../../abstract/AbstractLogin';

interface LoginStateInterface {
    username: string;
    errors: null | {
        username: Array<string>;
    };
}

interface LoginPropsInterface {
    saveLoginUsername: Function;
    title?: string;
    buttonTitle?: string;
    errors?: null | {
        username: Array<string>;
    };
}

export default class Login extends AbstractLogin<LoginPropsInterface, LoginStateInterface> {
    static defaultProps = {
        title: 'Welcome back!',
        buttonTitle: 'Done',
    };

    constructor(props: LoginPropsInterface) {
        super(props);

        this.state = {
            username: '',
            errors: props.errors || null,
        };
    }

    private onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            username: event.target.value,
            errors: this.validate(event.target.value),
        });
    };

    private submitForm = () => {
        if (!this.state.errors) {
            const validate = this.validate(this.state.username);
            if (!validate) {
                this.props.saveLoginUsername({username: this.state.username.toLowerCase()});
            } else {
                this.setState({
                    errors: validate,
                });
            }
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.block}>
                    <div className={styles.title}>
                        {this.props.title}
                        <p className={styles.reminder}>
                            <span>Reminder</span>: your username was created using the following formula: the last two
                            digits of your phone number, how many brothers you have, and the first two letters of your
                            middle name
                        </p>
                    </div>
                    <div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.submitForm();
                            }}
                            className={styles.form}
                            noValidate
                            autoComplete="off"
                        >
                            <div className={styles.containerTextField}>
                                <TextInput
                                    type="text"
                                    label="Enter username..."
                                    value={this.state.username}
                                    onInputChange={this.onInputChange}
                                    altCorrect="You have entered correct username"
                                    altIncorrect="You have entered incorrect username"
                                    error={this.state.errors?.username[0] || this.props.errors?.username[0] || null}
                                />
                            </div>
                            <div className={styles.buttonContainer}>
                                <MainButton
                                    text={this.props.buttonTitle}
                                    isActive={!this.state.errors && this.state.username.length > 0}
                                    onButtonClick={this.submitForm}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
