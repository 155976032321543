import {put, takeEvery, call} from 'redux-saga/effects';
import {REQUEST_SET_DEMOGRAPHIC_QUESTIONS} from './../constants/actionTypes';
import {sendDemographicQuestions} from './apiRequests/demographic';

export function* setDemographicQuestions() {
    yield takeEvery(`${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}`, function* (params: {
        type: string;
        studentId: string;
        answers: {[key: string]: string};
    }) {
        const getDemographicQuestionsResponse = yield call(sendDemographicQuestions, {
            studentId: params.studentId,
            demography: params.answers,
        });
        if (getDemographicQuestionsResponse.message) {
            yield put({
                type: `${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}_ERROR`,
                success: false,
                ...getDemographicQuestionsResponse,
            });
        } else {
            yield put({
                type: `${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}_SUCCESS`,
                success: true,
                demography: getDemographicQuestionsResponse.demography,
            });
        }
    });
}
