import {RootStateInterface} from 'js/reducers/rootReducer';
import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import styles from './styles/HandleIt.module.scss';
import {BubblesInterface, QuestionDataInterface} from '../interfaces';
import {getQuestions, setBubbleNumber} from '../actions/questionActions';
import {getCheckMorningQuestionIds, getCheckAfternoonQuestionIds} from '../helpers/checkQuestions';
import {LightMainButton} from './../common/buttons/lightMineButton';
import {MainButton} from './../common/buttons/mainButton';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';
import Loader from 'js/pages/Loader';

const checkQuestionIdStart = 600;

type BubbleType = 'morningBubbles' | 'afternoonBubbles';
interface HandleItProps extends RouteComponentProps<{slug: string}> {
    bubbleNumber: number;
    questionNumber: number;
    questionsMode: 'morningQuestions' | 'afternoonQuestions';
    morningBubbles: BubblesInterface;
    afternoonBubbles: BubblesInterface;
    getCheckMorningQuestions: Function;
    getCheckAfternoonQuestions: Function;
    morningQuestions: QuestionDataInterface[];
    afternoonQuestions: QuestionDataInterface[];
    setBubbleNumber: Function;
    morningQuestionIds: number[];
    afternoonQuestionIds: number[];
    darkmode?: boolean;
}

export class HandleIt extends React.Component<HandleItProps, {}> {
    componentDidMount() {
        if (
            this.props.questionsMode === 'morningQuestions' &&
            !this.props.morningQuestions.length &&
            this.props.morningQuestionIds.length
        ) {
            this.props.getCheckMorningQuestions(this.props.morningQuestionIds);
        }
        if (
            this.props.questionsMode === 'afternoonQuestions' &&
            !this.props.afternoonQuestions.length &&
            this.props.afternoonQuestionIds.length
        ) {
            this.props.getCheckAfternoonQuestions(this.props.afternoonQuestionIds);
        }
    }

    componentWillReceiveProps(nextProps: HandleItProps) {
        if (
            this.props.questionsMode === 'morningQuestions' &&
            !this.props.morningQuestions.length &&
            nextProps.morningQuestionIds.length
        ) {
            this.props.getCheckMorningQuestions(nextProps.morningQuestionIds);
        }
        if (
            this.props.questionsMode === 'afternoonQuestions' &&
            !this.props.afternoonQuestions.length &&
            nextProps.afternoonQuestionIds.length
        ) {
            this.props.getCheckAfternoonQuestions(nextProps.afternoonQuestionIds);
        }
    }

    getBubbleType(): BubbleType {
        return this.props.questionsMode === 'morningQuestions' ? 'morningBubbles' : 'afternoonBubbles';
    }

    calculateBubblesDone(): number {
        const bubbleType = this.getBubbleType();
        let bubbleDoneCount = 0;
        Object.keys(this.props[bubbleType]).forEach((bubbleNumber: string) => {
            if (!this.props[bubbleType][parseInt(bubbleNumber, 10)].done) {
                return;
            }

            const questionsCount = this.props[bubbleType][parseInt(bubbleNumber, 10)].questions.length;
            const questionsAnswered = this.countQuestionsAnswered(parseInt(bubbleNumber, 10));

            bubbleDoneCount +=
                questionsCount === questionsAnswered && this.isCheckQuestionPass(parseInt(bubbleNumber, 10)) ? 1 : 0;
        });

        return bubbleDoneCount;
    }

    countQuestionsAnswered(bubbleNumber: number) {
        const bubbleType = this.getBubbleType();
        return this.props[bubbleType][bubbleNumber].questions.reduce(
            (accumulator, question) => (accumulator += Number(question.skipReason === null)),
            0
        );
    }

    isCheckQuestionPass(bubbleNumber: number = this.props.bubbleNumber): boolean {
        const bubbleType = this.getBubbleType();
        const {questionsMode} = this.props;
        const checkQuestion = this.props[bubbleType][bubbleNumber].questions.find(
            (question) => question.questionId > checkQuestionIdStart
        );
        const checkQuestionData = this.props[questionsMode].find(
            (question) => question.id === checkQuestion?.questionId
        );

        return checkQuestion?.answer === checkQuestionData?.correctAnswer;
    }

    continue = () => {
        const bubbleType = this.getBubbleType();
        if (this.props.bubbleNumber + 1 < Object.keys(this.props[bubbleType]).length) {
            this.props.setBubbleNumber(this.props.bubbleNumber + 1, this.props.questionsMode);
            this.props.history.push(`/${this.props.match.params.slug}/journey/${this.props.questionsMode}`);
        } else {
            this.props.history.push(`/${this.props.match.params.slug}/journey`);
        }
    };

    tryAgain = () => {
        this.props.history.push(`/${this.props.match.params.slug}/journey/${this.props.questionsMode}`);
    };

    private renderStarIcon = () => {
        if (this.props.darkmode) {
            return require('./../../images/icons/star-white.svg');
        }

        return require('./../../images/icons/star.svg');
    };

    private renderButton = (text: string) => {
        if (this.props.darkmode) {
            return (
                <MainButton
                    isDefaultActive={true}
                    onButtonClick={this.tryAgain}
                    text={text}
                    darkmode={this.props.darkmode}
                />
            );
        }

        return (
            <LightMainButton
                isDefaultActive={true}
                onButtonClick={this.tryAgain}
                text={text}
                darkmode={this.props.darkmode}
            />
        );
    };

    protected goToJourney = () => {
        this.props.history.push(`/${this.props.match.params.slug}/journey`);
    };

    render() {
        if (!Object.keys(this.props.morningBubbles).length || !Object.keys(this.props.afternoonBubbles).length) {
            return <Loader />;
        }

        const {bubbleNumber} = this.props;
        let questionsCount;
        let questionsAnswered;
        const bubbleType = this.getBubbleType();
        questionsCount = this.props[bubbleType][bubbleNumber].questions.length;
        questionsAnswered = this.countQuestionsAnswered(bubbleNumber);
        const passed = questionsCount === questionsAnswered && this.isCheckQuestionPass();

        return (
            <div className={`${styles.container} ${this.props.darkmode ? styles.containerDark : ''}`}>
                <div className={`${styles.header} clear_fix`}>
                    <div
                        tabIndex={0}
                        aria-pressed="false"
                        aria-label="Go to Journey"
                        role="button"
                        className={styles.back}
                        onKeyDown={(e) => onKeyDown(e, this.goToJourney)}
                        onKeyUp={(e) => onKeyUp(e, this.goToJourney)}
                        onClick={this.goToJourney}
                    >
                        Back to Home
                    </div>
                    <div className={styles.starContainer}>
                        <span>{this.calculateBubblesDone()}</span>
                        <img className={styles.skate} src={this.renderStarIcon()} alt="" />
                    </div>
                </div>
                <div className={styles.containerContent}>
                    <div className={styles.scopeContainer}>
                        <div className={styles.circle}>
                            <div className={styles.whiteCircle}>
                                <div className={styles.glassContainer}>
                                    <img
                                        className={styles.skate}
                                        src={require('./../../images/icons/score-glass.svg')}
                                        alt="score glass icon"
                                    />
                                    <span>
                                        {questionsAnswered}/{questionsCount}
                                    </span>
                                </div>
                            </div>
                            {passed ? (
                                <img
                                    className={styles.stroke}
                                    src={require('./../../images/icons/star-full.svg')}
                                    alt="star"
                                />
                            ) : (
                                <img
                                    className={styles.stroke}
                                    src={require('./../../images/icons/stroke.svg')}
                                    alt="star"
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.containerText}>
                        <div className={styles.title}>You did it!</div>
                        <div className={styles.text}>
                            {passed ? (
                                <>
                                    You get an extra reward for attentiveness. <br />
                                    Keep up the good work!
                                </>
                            ) : (
                                <>
                                    But you didn't receive an extra <br />
                                    reward. Try again, just be attentive!
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.containerButton} ${passed ? styles.containerButtonContinue : ''}`}>
                    {passed ? (
                        <div className={`${styles.button} ${styles.buttonActive}`} onClick={this.continue}>
                            {this.renderButton('Continue')}
                        </div>
                    ) : (
                        <>
                            <div className={`${styles.button} ${styles.buttonActive}`} onClick={this.continue}>
                                {this.renderButton('Continue')}
                            </div>
                            <div
                                className={`${styles.skip}`}
                                onClick={this.tryAgain}
                                tabIndex={0}
                                aria-pressed="false"
                                aria-label="Try again"
                                role="button"
                                onKeyDown={(e) => onKeyDown(e, this.tryAgain)}
                                onKeyUp={(e) => onKeyUp(e, this.tryAgain)}
                            >
                                Try Again 
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const HandleItWithRouter = withRouter(HandleIt);

const mapStateToProps = function (state: RootStateInterface) {
    return {
        bubbleNumber: state.config.bubbleNumber,
        questionNumber: state.config.questionNumber,
        questionsMode: state.config.questionsMode,
        morningBubbles: state.surveyTemplate.morningBubbles,
        morningQuestions: state.questions.morningQuestions,
        afternoonBubbles: state.surveyTemplate.afternoonBubbles,
        afternoonQuestions: state.questions.afternoonQuestions,
        morningQuestionIds: state.surveyTemplate.morningQuestionIds,
        afternoonQuestionIds: state.surveyTemplate.afternoonQuestionIds,
        darkmode: state.config.darkmode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<{type: string}>) => ({
    getCheckMorningQuestions(ids: number[]) {
        const checkMorningQuestionIds = getCheckMorningQuestionIds();
        return dispatch(getQuestions('morningQuestions', ids.concat(checkMorningQuestionIds)));
    },
    getCheckAfternoonQuestions(ids: number[]) {
        const checkAfternoonQuestionIds = getCheckAfternoonQuestionIds();
        return dispatch(getQuestions('afternoonQuestions', ids.concat(checkAfternoonQuestionIds)));
    },
    setBubbleNumber(number: number, mode: 'morningQuestions' | 'afternoonQuestions') {
        return dispatch(setBubbleNumber(number, mode));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HandleItWithRouter);
