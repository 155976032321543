import React from 'react';
import styles from './styles/Welcome.module.scss';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {ArrowButton} from './../common/buttons/arrowButton';

interface WelcomeProps extends RouteComponentProps<{slug: string}> {}

export class Welcome extends React.Component<WelcomeProps, {}> {
    render() {
        return (
            <div className={styles.containerWelcome}>
                <div className={styles.containerWelcomeText}>
                    <h1 className={styles.welcomeText}>Welcome</h1>
                    <h2 className={styles.welcomeSubText}>
                        We are happy to <br />
                        see you here.
                    </h2>
                </div>
                <div>
                    <div className={styles.buttonContainer}>
                        <ArrowButton
                            text="Let’s start"
                            onButtonClick={() => this.props.history.push(`/${this.props.match.params.slug}/hello`)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const WelcomeWithRouter = withRouter(Welcome);

export default WelcomeWithRouter;
