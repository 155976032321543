import {spaceKeyCode} from '../constants/keyboard';

export const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, func: Function, blurElement = false) => {
    if (event.keyCode === spaceKeyCode) {
        event.preventDefault();
    } else if (event.key === 'Enter') {
        event.preventDefault();
        func();
        if (blurElement) {
            event.currentTarget.blur();
        }
    }
};

export const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>, func: Function, blurElement = false) => {
    if (event.keyCode === spaceKeyCode) {
        event.preventDefault();
        func();
        if (blurElement) {
            event.currentTarget.blur();
        }
    }
};
