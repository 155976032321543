import React from 'react';
import styles from './styles/SelectGender.module.scss';
import {onKeyDown, onKeyUp} from '../../helpers/accessibility';

interface SelectGenderPropsInterface {
    onChange: Function;
    question: {
        textEn: string;
        category: string;
        questionType: string;
        demographyParameter: string;
        options: {
            label: string;
            value: string;
        }[];
        id: number;
    };
    answer: string;
}

const bubbleStyles = [styles.male, styles.female, styles.alternative];

export default class SelectGender extends React.Component<
    SelectGenderPropsInterface,
    {
        genderActive: string;
    }
> {
    constructor(props: SelectGenderPropsInterface) {
        super(props);

        this.state = {
            genderActive: this.props.answer,
        };
    }

    componentWillReceiveProps(nextProps: SelectGenderPropsInterface) {
        if (nextProps.answer && nextProps.answer !== this.state.genderActive) {
            this.setState({
                genderActive: nextProps.answer,
            });
        }
    }

    private onAnswerChange = (value: string) => {
        this.props.onChange(this.props.question, value);
        this.setState({genderActive: value});
    };

    render() {
        return (
            <div className={`${styles.container} clear_fix`}>
                {this.props.question.options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className={`${styles.gender} ${bubbleStyles[index]} ${
                                this.state.genderActive === option.value ? styles.genreCircleActive : ''
                            }`}
                            onClick={() => this.onAnswerChange(option.value)}
                            onKeyDown={(e) => onKeyDown(e, () => this.onAnswerChange(option.value))}
                            onKeyUp={(e) => onKeyUp(e, () => this.onAnswerChange(option.value))}
                            tabIndex={0}
                            role="button"
                        >
                            <div
                                className={`${this.state.genderActive === option.value ? styles.genreActive : ''} ${
                                    styles.genderTransparent
                                }`}
                            >
                                {option.label}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
