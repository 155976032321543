import {put, takeEvery, call} from 'redux-saga/effects';
import {getPinnedQuestions, updatePinnedQuestions} from './apiRequests/pinnedQuestions';
import {REQUEST_PINNED_QUESTIONS, UPDATE_PINNED_QUESTIONS} from '../constants/actionTypes';

export function* getPinnedQuestionsSaga() {
    yield takeEvery(REQUEST_PINNED_QUESTIONS, function* (params: {
        type: string;
        dimension: string;
        schoolSurveyId: string;
    }) {
        const getPinnedQuestionsResponse = yield call(getPinnedQuestions, params.dimension, params.schoolSurveyId);

        if (getPinnedQuestionsResponse.message) {
            yield put({
                type: `${REQUEST_PINNED_QUESTIONS}_ERROR`,
            });
        } else {
            yield put({
                type: `${REQUEST_PINNED_QUESTIONS}_SUCCESS`,
                pinnedQuestions: getPinnedQuestionsResponse.pinnedQuestions,
                dimension: params.dimension,
            });
        }
    });
}

export function* updatePinnedQuestionsSaga() {
    yield takeEvery(UPDATE_PINNED_QUESTIONS, function* (params: {
        type: string;
        dimension: string;
        schoolSurveyId: string;
        pinnedQuestions: number[];
    }) {
        const updatePinnedQuestionsResponse = yield call(
            updatePinnedQuestions,
            params.dimension,
            params.schoolSurveyId,
            params.pinnedQuestions
        );

        if (updatePinnedQuestionsResponse.message) {
            yield put({
                type: `${UPDATE_PINNED_QUESTIONS}_ERROR`,
            });
        } else {
            yield put({
                type: `${UPDATE_PINNED_QUESTIONS}_SUCCESS`,
                pinnedQuestions: updatePinnedQuestionsResponse.pinnedQuestions,
                dimension: params.dimension,
            });
        }
    });
}
