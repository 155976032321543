import {LOAD_RESULTS, LOAD_DIMENSION_RESULTS, LOAD_DIMENSION_OBSERVATION_RESULTS} from '../constants/actionTypes';

export function loadResult() {
    return {
        type: LOAD_RESULTS,
    };
}

export function loadDimensionResult(dimensionId: string) {
    return {
        type: LOAD_DIMENSION_RESULTS,
        dimensionId,
    };
}

export function loadDimensionObservationResult(dimensionId: string) {
    return {
        type: LOAD_DIMENSION_OBSERVATION_RESULTS,
        dimensionId,
    };
}
