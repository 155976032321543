import {RootStateInterface} from '../reducers/rootReducer';

export const getQuestionData = (state: RootStateInterface) => {
    const {questionNumber = 0, bubbleNumber = 0, questionsMode = 'morningQuestions'} = state.config;
    const bubbleType = questionsMode === 'morningQuestions' ? 'morningBubbles' : 'afternoonBubbles';
    return state.surveyTemplate[bubbleType][bubbleNumber].questions[questionNumber];
};
export const getSurveyId = (state: RootStateInterface) => state.survey.id;

export const getStudentId = (state: RootStateInterface) => state.auth.studentId;

export const getStudentUsername = (state: RootStateInterface) => state.auth.username;

export const getAnswer = (state: RootStateInterface, questionId: number) => state.answers.answer[questionId];

export const getBubbles = (state: RootStateInterface) => ({
    morningBubbles: state.surveyTemplate.morningBubbles,
    afternoonBubbles: state.surveyTemplate.afternoonBubbles,
});

export const getSurveyData = (state: RootStateInterface) => state.survey;

export const getDemographyAnswers = (state: RootStateInterface) => state.demographicQuestions.answers;

export const getAnswers = (state: RootStateInterface) => state.answers.answer;
