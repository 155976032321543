import React, {Dispatch} from 'react';
import styles from './styles/BurgerMenu.module.scss';
import {logOut} from './../actions/authActions';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {switchDarkmode} from './../actions/settingsActions';
import {RootStateInterface} from './../reducers/rootReducer';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';

const IOSSwitch = createStyles({
    root: {
        width: 52,

        height: 26,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(26px)',
            backgroundImage: 'linear-gradient(to bottom, #a40080, #e51247);',
            '& + $track': {
                backgroundImage: 'linear-gradient(to bottom, #a40080, #e51247);',
                opacity: 1,
                border: 'none',
            },
        },
        '&$thumb': {
            color: 'linear-gradient(to bottom, #a40080, #e51247);',
            backgroundImage: 'linear-gradient(to bottom, #a40080, #e51247);',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 22,
        height: 22,
        backgroundImage: 'linear-gradient(to top, #a40080, #e51247);',
        border: '1px solid white',
    },
    track: {
        borderRadius: 26 / 2,
        opacity: 1,
        backgroundColor: '#e4eaf6',
    },
    checked: {
        thumb: {
            width: 24,
            height: 24,
            backgroundColor: '#e4eaf6',
        },
    },
});

const Switcher = withStyles(IOSSwitch)(Switch);

interface BurgerMenuPropsInterface extends RouteComponentProps<{slug?: string}> {
    logOut: Function;
    switchDarkmode: Function;
    showHomeMenu?: boolean;
    darkmode: boolean;
    defaultColorMode?: boolean;
    canChangeDarkMode?: boolean;
}

export class BurgerMenu extends React.Component<
    BurgerMenuPropsInterface,
    {
        menuActive: boolean;
    }
> {
    static defaultProps = {
        showHomeMenu: true,
    };

    constructor(props: BurgerMenuPropsInterface) {
        super(props);

        this.state = {
            menuActive: false,
        };
    }

    private logout = () => {
        this.props.logOut();
        this.props.history.push(`/${this.props.match.params.slug}`);
    };

    private renderBurgerMenuImage = () => {
        if (this.props.defaultColorMode || !this.props.darkmode) {
            return require('./../../images/icons/burger.svg');
        }

        return require('./../../images/icons/burger-white.svg');
    };

    toggleMenu = () => {
        this.setState({
            menuActive: !this.state.menuActive,
        });
    };

    closeMenu = () => {
        this.setState({menuActive: false});
    };

    goHome = () => {
        this.props.history.push(`/${this.props.match.params.slug}/journey`);
    };

    goTerms = () => {
        this.props.history.push(`/${this.props.match.params.slug}/terms-info`);
    };

    render() {
        return (
            <div className={styles.container}>
                <img
                    src={this.renderBurgerMenuImage()}
                    alt="Menu"
                    onClick={this.toggleMenu}
                    onKeyDown={(e) => onKeyDown(e, this.toggleMenu)}
                    onKeyUp={(e) => onKeyUp(e, this.toggleMenu)}
                    role="button"
                    {...!this.state.menuActive ? {tabIndex:0} : {}}
                />
                <div onClick={this.closeMenu} className={`${styles.backdrop} ${!this.state.menuActive ? styles.panelHidden : ''}`}></div>
                <div className={`${styles.panel} ${!this.state.menuActive ? styles.panelHidden : ''}`}>
                    <div className={styles.panelContainer}>
                        <div className={styles.closeButton}>
                            <img
                                src={require('./../../images/icons/close.svg')}
                                alt="close menu"
                                onClick={this.closeMenu}
                                onKeyDown={(e) => onKeyDown(e, this.closeMenu)}
                                onKeyUp={(e) => onKeyUp(e, this.closeMenu)}
                                {...this.state.menuActive ? {tabIndex:0} : {}}
                                role="button"
                            />
                        </div>
                        <div className={styles.listButtons}>
                            {this.props.showHomeMenu ? (
                                <div
                                    className={styles.button}
                                    onClick={this.goHome}
                                    onKeyDown={(e) => onKeyDown(e, this.goHome)}
                                    {...this.state.menuActive ? {tabIndex:0} : {}}
                                    role="button"
                                >
                                    Home
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div
                                className={styles.button}
                                onClick={this.goTerms}
                                onKeyDown={(e) => onKeyDown(e, this.goTerms)}
                                {...this.state.menuActive ? {tabIndex:0} : {}}
                            >
                                Informed Assent
                            </div>
                            {this.props.canChangeDarkMode ?
                            <div className={styles.button}>
                                Dark mode
                                <div
                                    className={`${styles.darkmodeSwitcherContainer} darkmodeSwitcherContainerGlobal`}
                                    {...this.state.menuActive ? {tabIndex:0} : {}}
                                    aria-pressed="false"
                                    aria-label="On/Off darkmode"
                                    role="button"
                                    onKeyDown={(e) => onKeyDown(e, this.props.switchDarkmode)}
                                    onKeyUp={(e) => onKeyUp(e, this.props.switchDarkmode)}
                                >
                                    <Switcher
                                        checked={this.props.darkmode}
                                        onChange={() => this.props.switchDarkmode()}
                                    />
                                </div>
                            </div> : <div></div>}
                            <div className={`${styles.button} ${styles.buttonTerms}`}>
                                <span onClick={() => {
                                    window.open('https://inspiredstudents.org/terms-of-use/', '_blank')
                                }}>Terms of Services</span>
                            </div>
                            <div
                                className={styles.button}
                                onClick={this.logout}
                                onKeyDown={(e) => onKeyDown(e, this.logout)}
                                {...this.state.menuActive ? {tabIndex:0} : {}}
                                role="button"
                            >
                                Logout
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        darkmode: state.config.darkmode,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<{
        type: string;
    }>
) => ({
    logOut() {
        return dispatch(logOut());
    },
    switchDarkmode() {
        return dispatch(switchDarkmode());
    },
});

const BurgerMenuWithRouter = withRouter(BurgerMenu);

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenuWithRouter);
