import axios from 'axios';
import {setDefaultAuthorizationHeader} from './defaultHeaders';

let refreshRequest: any;

export default axios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    async function (error) {
        if (error.response && error.response.data) {
            if (error.response.data.statusCode === 401 && error.response.config.url.indexOf('/api/auth/login') === -1) {
                const response = error.response;
                if (!refreshRequest) {
                    const role = localStorage.getItem('role')
                    let token = localStorage.getItem('refreshToken')
                    if (role === 'SUPER_ADMIN' || role === 'SCHOOL_ADMIN') {
                        let adminToken = localStorage.getItem('rToken')
                        
                        if (adminToken) {
                            token = adminToken
                        }
                    }

                    refreshRequest = axios.post(`${process.env.REACT_APP_API_HOST}/auth/refresh`, {
                        refreshToken: token,
                    });
                }

                const refreshResponse = await refreshRequest;

                if (refreshResponse.statusCode) {
                    return Promise.reject(refreshResponse.message);
                }

                const {refreshToken, accessToken} = refreshResponse;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                setDefaultAuthorizationHeader();
                refreshRequest = null;
                if (response.config.method === 'get') {
                    return axios.get(response.config.url);
                } else if (response.config.method === 'put') {
                    return axios.put(response.config.url, response.config.data);
                } else if (response.config.method === 'post') {
                    return axios.post(response.config.url, response.config.data);
                } else {
                    console.log('Check request method');
                }
            }
            return error.response.data;
        }

        return error;
    }
);
