import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {throttle} from 'lodash';
import styles from './styles/PublicDevice.module.scss';
import {logOut} from './../actions/authActions';
import {RootStateInterface} from '../reducers/rootReducer';
import {MAX_IPAD_WIDTH} from './../constants/dimensions';
import {inactiveIntervalMc} from '../constants/publicDevice';
import {spaceKeyCode} from '../constants/keyboard';

interface PublicDeviceProps extends RouteComponentProps<{slug: string}> {
    logOut: Function;
    username: string | null;
    width: number;
    height: number;
}

export class PublicDevice extends React.Component<
    PublicDeviceProps,
    {
        activeCircle: string;
        activeWhy: boolean;
    }
> {
    private timeoutId: any;
    constructor(props: PublicDeviceProps) {
        super(props);
        this.timeoutId = 0;

        this.state = {
            activeCircle: '',
            activeWhy: false,
        };

        if (!this.props.username) {
            this.props.history.push(`/${this.props.match.params.slug}`);
        }

        this.resetTimer = throttle(this.resetTimer, 100);
    }

    startTimer() {
        this.timeoutId = window.setTimeout(this.doInactive, inactiveIntervalMc);
    }

    doInactive = () => {
        window.sessionStorage.removeItem('lastActiveAt');
        this.props.logOut();
        this.props.history.push(`/${this.props.match.params.slug}/login/username`);
        document.removeEventListener('mousemove', this.resetTimer);
        document.removeEventListener('keyup', this.resetTimer);
        document.removeEventListener('touchmove', this.resetTimer);
        document.removeEventListener('touchend', this.resetTimer);
    };

    setupTimers() {
        document.addEventListener('mousemove', this.resetTimer);
        document.addEventListener('keyup', this.resetTimer);
        document.addEventListener('touchmove', this.resetTimer);
        document.addEventListener('touchend', this.resetTimer);
        this.startTimer();
    }

    resetTimer = () => {
        window.clearInterval(this.timeoutId);
        this.startTimer();
        window.sessionStorage.setItem('lastActiveAt', new Date().toISOString());
    };

    handleChooseDevice(answer: 'yes' | 'no') {
        this.setState({activeCircle: answer});
        if (answer === 'yes') {
            this.setupTimers();
        }
        this.props.history.push(`/${this.props.match.params.slug}/journey/demographical`);
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, answer: 'yes' | 'no') => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.handleChooseDevice(answer);
        }
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLDivElement>, answer: 'yes' | 'no') => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.handleChooseDevice(answer);
        }
    }

    onClickAsking = () => {
        this.setState((state) => ({activeWhy: !this.state.activeWhy}));
    }

    onKeyDownAsking = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.onClickAsking();
        }
    }

    onKeyUpAsking = (event:React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.onClickAsking();
        }
    }

    renderPublicDeviceTitle = () => {
        if (this.props.width > MAX_IPAD_WIDTH) {
            return (
                <div className={styles.title}>
                    Does anyone else use this <br />
                    device besides you?
                </div>
            );
        }
        return (
            <div className={styles.title}>
                Does anyone else <br />
                use this device <br /> besides you?
            </div>
        );
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.title}>
                    Does anyone else <br />
                    use this device <br /> besides you?
                </div>
                <div className={styles.circleContainer}>
                    <div
                        className={`${styles.yesCircle} ${styles.circle} ${
                            this.state.activeCircle === 'yes' ? styles.circleActive : ''
                        }`}
                        onClick={() => this.handleChooseDevice('yes')}
                        onKeyUp={(e) => this.onKeyUp(e, 'yes')}
                        onKeyDown={(e) => this.onKeyDown(e, 'yes')}
                        tabIndex={0}
                        role="button"
                    >
                        <div className={styles.whiteCircle}>
                            <span>YES</span>
                        </div>
                    </div>
                    <div
                        className={`${styles.noCircle} ${styles.circle} ${
                            this.state.activeCircle === 'no' ? styles.circleActive : ''
                        }`}
                        onClick={() => this.handleChooseDevice('no') }
                        onKeyUp={(e) => this.onKeyUp(e, 'no')}
                        onKeyDown={(e) => this.onKeyDown(e, 'no')}
                        tabIndex={0}
                        role="button"
                    >
                        <div className={styles.whiteCircle}>
                            <span>NO</span>
                        </div>
                    </div>
                </div>
                <div className={styles.whyContainer}>
                    <div
                        className={`${styles.whyContainerTitle} ${this.state.activeWhy ? styles.activeWhyTitle : ''}`}
                        onClick={this.onClickAsking}
                        onKeyUp={this.onKeyUpAsking}
                        onKeyDown={this.onKeyDownAsking}
                        aria-expanded={this.state.activeWhy}
                        aria-controls="answer"
                        tabIndex={0}
                        role="button"
                    >
                        Why are you asking this?
                    </div>
                    <div id="answer" className={`${styles.whyText} ${this.state.activeWhy ? styles.activeWhyText : ''}`}>
                        We'll automatically log you out if you are using a public device and are inactive for 5 minutes.
                    </div>
                </div>
            </div>
        );
    }
}

const PublicDeviceWithRouter = withRouter(PublicDevice);

const mapStateToProps = function (state: RootStateInterface) {
    return {
        username: state.auth.username,
        width: state.window.width,
        height: state.window.height,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<{type: string}>) => ({
    logOut() {
        return dispatch(logOut({publicDevice: true}));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicDeviceWithRouter);
