import {all, takeEvery, put, call, select} from 'redux-saga/effects';
import {SAVE_ANSWER, SAVE_SUB_QUESTIONS_ANSWER} from '../constants/actionTypes';
import {saveAnswer, updateAnswer} from './apiRequests/answer';
import {getQuestionData, getSurveyId, getAnswer} from './selectors';
import {SubQuestionsAnswersInterface} from '../interfaces';

export function* saveAnswerSaga() {
    yield takeEvery(SAVE_ANSWER, function* (params: {type: string; answer: number; skipReason: string}) {
        const questionData = yield select(getQuestionData);
        const {questionId} = questionData;
        const schoolSurveyId = yield select(getSurveyId);
        const answer = yield select(getAnswer, questionId);
        let answerResponse;
        if (!answer) {
            answerResponse = yield call(saveAnswer, {
                schoolSurveyId,
                questionId,
                value: params.answer,
                skipReason: params.skipReason,
            });
        } else {
            answerResponse = yield call(updateAnswer, {
                id: answer.id,
                value: params.answer,
                skipReason: params.skipReason,
            });
        }
        if (answerResponse.message) {
            yield put({
                type: `${SAVE_ANSWER}_ERROR`,
                message: answerResponse.message,
                ...answerResponse,
            });
        } else {
            yield put({
                type: `${SAVE_ANSWER}_SUCCESS`,
                ...answerResponse,
            });
        }
    });
}

export function* saveSubQuestionsAnswerSaga() {
    yield takeEvery(SAVE_SUB_QUESTIONS_ANSWER, function* (params: {
        type: string;
        subQuestions: SubQuestionsAnswersInterface;
    }) {
        const schoolSurveyId = yield select(getSurveyId);
        const questionIds: number[] = Object.keys(params.subQuestions).map((id) => parseInt(id, 10));
        const answer = yield select(getAnswer, questionIds[0]);

        let answersResponse;
        if (!answer) {
            answersResponse = yield all(
                questionIds.map((questionId: number) =>
                    call(saveAnswer, {
                        schoolSurveyId,
                        questionId,
                        value: params.subQuestions[questionId].answer,
                    })
                )
            );
        } else {
            answersResponse = yield all(
                questionIds.map((questionId: number) =>
                    call(updateAnswer, {
                        id: answer.id,
                        value: params.subQuestions[questionId].answer,
                    })
                )
            );
        }

        yield all(
            answersResponse.map(function* (answerResponse: {questionId: number}) {
                yield put({
                    type: `${SAVE_ANSWER}_SUCCESS`,
                    ...answerResponse,
                });
            })
        );
    });
}
