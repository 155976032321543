import React, {Dispatch} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {clearUserExists, setUsername} from './../../actions/authActions';

import {Back} from './../../common/navigation/back';
import Login from './../../components/auth/Login';
import {RootStateInterface} from 'js/reducers/rootReducer';
import {connect} from 'react-redux';
import styles from './styles/LoginAuth.module.scss';

interface LoginUsernamePropsInterface extends RouteComponentProps<{slug: string}> {
    error: string;
    success: boolean;
    schoolId: string | null;
    setUsername: Function;
    clearErrors: Function;
    publicDevice: boolean;
    username: string | null;
    hasUser?: boolean | null;
    clearUserExists: Function;
}

export class LoginUsername extends React.Component<LoginUsernamePropsInterface, {}> {
    componentWillReceiveProps (nextProps: LoginUsernamePropsInterface) {
        if (this.props.username && nextProps.hasUser) {
            this.props.clearUserExists()
            this.props.history.push(`/${this.props.match.params.slug}/login/password`);
        }
    }

    protected setUsername = (creds: {username: string}) => {
        this.props.setUsername({username: `${creds.username}+${this.props.schoolId}`});
    };

    render() {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.containerLoginBackButton}>
                    <Back onNavigationClick={() => {
                        this.props.clearUserExists()
                        this.props.history.push(`/${this.props.match.params.slug}/hello`)}
                    } />
                </div>
                <div></div>
                <Login
                    errors={!this.props.hasUser && this.props.username ? {
                        username: ['There are no users with this username']
                    } : null}
                    saveLoginUsername={this.setUsername}
                    title={this.props.publicDevice ? 'You have been logged out due to inactivity' : 'Welcome back!'}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<{type: string; username?: string}>) => ({
    setUsername(params: {type?: string; username: string}) {
        return dispatch(setUsername(params));
    },
    clearUserExists(params: {type?: string}) {
        return dispatch(clearUserExists())
    }
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        publicDevice: state.auth.publicDevice,
        username: state.auth.username,
        hasUser: state.auth.hasUser,
        schoolId: state.survey.schoolId,
    };
};

const LoginUsernameWithRouter = withRouter(LoginUsername);

export default connect(mapStateToProps, mapDispatchToProps)(LoginUsernameWithRouter);
