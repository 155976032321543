import {chunk, concat, shuffle, dropRight, without} from 'lodash';
import {
    REQUEST_SURVEY_TEMPLATE,
    SAVE_ANSWER,
    SET_BUBBLE_DONE,
    LOAD_STUDENT,
    SAVE_SUB_QUESTIONS_ANSWER,
    LOGOUT_USER,
} from './../../constants/actionTypes';
import {QuestionInterface, BubblesInterface, SubQuestionsAnswersInterface} from '../../interfaces';

interface SurveyTemplateStateInterface {
    requestInProgress: boolean;
    loaded: boolean;
    demographyQuestionIds: number[];
    skipReasonQuestionId: number;
    morningQuestionIds: number[];
    afternoonQuestionIds: number[];
    success: boolean;
    message: string;
    morningBubbles: BubblesInterface;
    afternoonBubbles: BubblesInterface;
}

interface ActionInterface {
    type: string;
    demographyQuestionIds?: number[];
    skipReasonQuestionId: number;
    morningQuestionIds: number[];
    afternoonQuestionIds: number[];
    subQuestionIds: number[];
    message?: string;
    answer?: string | null;
    bubbleNumber?: number;
    questionNumber?: number;
    mode?: 'morningBubbles' | 'afternoonBubbles';
    number?: number;
    skipReason?: string;
    bubbles?: {morningBubbles: BubblesInterface; afternoonBubbles: BubblesInterface};
    subQuestions?: SubQuestionsAnswersInterface;
}

const initialState: SurveyTemplateStateInterface = {
    requestInProgress: false,
    loaded: false,
    demographyQuestionIds: [],
    skipReasonQuestionId: 0,
    morningQuestionIds: [],
    afternoonQuestionIds: [],
    success: true,
    message: '',
    morningBubbles: {},
    afternoonBubbles: {},
};

const questionsInBubble = 7;
const checkQuestionsStart = {
    morning: 600,
    afternoon: 700,
};

function prepareQuestions(ids: number[], type: 'morning' | 'afternoon'): BubblesInterface {
    const mapIds: QuestionInterface[] = ids.map((id) => ({questionId: id, answer: null, skipReason: null}));
    const shuffleIds = shuffle(mapIds);
    let chunks = chunk(shuffleIds, questionsInBubble);
    if (chunks[chunks.length - 1].length < questionsInBubble / 2) {
        chunks[chunks.length - 2] = concat(chunks[chunks.length - 2], chunks[chunks.length - 1]);
        chunks = dropRight(chunks);
    }

    return chunks.reduce(
        (accumulator: BubblesInterface, questions: QuestionInterface[], index: number): BubblesInterface => {
            questions.push({questionId: checkQuestionsStart[type] + index + 1, answer: null, skipReason: null});
            accumulator[index] = {
                questions: shuffle(questions),
                done: false,
            };
            return accumulator;
        },
        {}
    );
}

export default function (state = initialState, action: ActionInterface): SurveyTemplateStateInterface {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case REQUEST_SURVEY_TEMPLATE:
            return {
                ...state,
                requestInProgress: true,
            };
        case `${REQUEST_SURVEY_TEMPLATE}_SUCCESS`:
            const {type, ...questionsIds} = action;

            let afternoonQuestionIds = [...questionsIds.afternoonQuestionIds];
            afternoonQuestionIds = without(afternoonQuestionIds, ...questionsIds.subQuestionIds);
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                success: true,
                message: '',
                morningBubbles: Object.keys(state.morningBubbles).length
                    ? state.morningBubbles
                    : prepareQuestions(questionsIds.morningQuestionIds, 'morning'),
                afternoonBubbles: Object.keys(state.afternoonBubbles).length
                    ? state.afternoonBubbles
                    : prepareQuestions(afternoonQuestionIds, 'afternoon'),
                ...questionsIds,
            };
        case `${REQUEST_SURVEY_TEMPLATE}_ERROR`:
            return {
                ...state,
                success: false,
                loaded: false,
                message: action?.message || '',
                requestInProgress: false,
            };
        case `${LOAD_STUDENT}_SUCCESS`:
            return {
                ...state,
                requestInProgress: false,
                success: true,
                message: '',
                ...action.bubbles,
            };
        case SAVE_ANSWER:
            const {
                mode = 'morningBubbles',
                bubbleNumber = 0,
                questionNumber = 0,
                answer = null,
                skipReason = null,
            } = action;
            const question = state[mode][bubbleNumber].questions[questionNumber];
            if (question) {
                question.answer = answer;
                question.skipReason = skipReason;
            }

            return {
                ...state,
            };
        case SAVE_SUB_QUESTIONS_ANSWER:
            const questionWithSubQuestions =
                state.afternoonBubbles[action.bubbleNumber || 0].questions[action.questionNumber || 0];
            if (questionWithSubQuestions) {
                questionWithSubQuestions.subQuestions = action.subQuestions;
            }

            return {
                ...state,
            };
        case SET_BUBBLE_DONE:
            const {number = 0} = action;
            state[action.mode || 'morningBubbles'][number].done = true;

            return {
                ...state,
            };
        default:
            return state;
    }
}
