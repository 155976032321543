import React from 'react';
import styles from './styles/lightMineButton.module.scss';
import {spaceKeyCode} from '../../constants/keyboard';

interface LightMainButtonPropsInterface {
    text: string;
    isDefaultActive?: boolean;
    isActive?: boolean;
    onButtonClick?: Function;
    darkmode?: boolean;
}

export class LightMainButton extends React.PureComponent<LightMainButtonPropsInterface, {}> {
    onClick = () => {
        if (this.props.onButtonClick) {
            this.props.onButtonClick();
        } 
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // The action button is activated by space on the keyup event, but the
        // default action for space is already triggered on keydown. It needs to be
        // prevented to stop scrolling the page before activating the button.
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.onClick();
        }
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.onClick();
        }
    }

    render() {
        let buttonClasslist = `${styles.doneButton}`;
        const buttonActive = this.props.isActive || this.props.isDefaultActive;
        if (buttonActive) buttonClasslist = buttonClasslist + ` ${styles.activeButton}`;
        return (
            <div
                className={`${styles.buttonContainer} ${buttonActive ? styles.buttonContainerActive : ''} ${
                    this.props.darkmode ? styles.buttonContainerDark : ''
                }`}
            >
                <div
                    onClick={this.onClick}
                    onKeyUp={this.onKeyUp}
                    onKeyDown={this.onKeyDown}
                    role="button"
                    tabIndex={0}
                    className={styles.buttonHoverHandler}
                ></div>
                <div className={buttonClasslist}>
                    <span>{this.props.text}</span>
                </div>
            </div>
        );
    }
}
