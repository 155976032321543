import {select, takeEvery, call, put} from 'redux-saga/effects';
import {SAVE_BUBBLES, SAVE_SECURITY_QUESTION} from '../constants/actionTypes';
import {getBubbles, getStudentId} from './selectors';
import {saveBubbles, saveSecurityQuestion} from './apiRequests/student';

export function* saveBubblesSaga() {
    yield takeEvery(SAVE_BUBBLES, function* () {
        const studentId = yield select(getStudentId);
        const bubbles = yield select(getBubbles);
        yield call(saveBubbles, {studentId, bubbles});
    });
}

export function* saveSecurityQuestionSaga() {
    yield takeEvery(SAVE_SECURITY_QUESTION, function* (params: {type: string; answer: string; questionId: number}) {
        const studentId = yield select(getStudentId);
        const response = yield call(saveSecurityQuestion, {
            studentId,
            answer: params.answer,
            questionId: params.questionId,
        });
        if (response.error) {
            yield put({
                type: `${SAVE_SECURITY_QUESTION}_ERROR`,
                message: response.message,
            });
        } else {
            yield put({
                type: `${SAVE_SECURITY_QUESTION}_SUCCESS`,
                ...response,
            });
        }
    });
}
