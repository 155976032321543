import {ON_RESOLUTION_CHANGE} from '../../constants/actionTypes';

interface ActionInterface {
    type?: string;
    width: number;
    height: number;
}

const initialState: {type?: string; width: number; height: number} = {
    width: window.innerWidth,
    height: window.innerHeight,
};

export default function windowReducer(
    state = initialState,
    action: ActionInterface
): {type?: string; width: number; height: number} {
    switch (action.type) {
        case `${ON_RESOLUTION_CHANGE}`:
            return {
                ...state,
                width: action.width,
                height: action.height,
            };
        default:
            return state;
    }
}
