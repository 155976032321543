import {put, takeEvery, call, select} from 'redux-saga/effects';
import {REQUEST_QUESTIONS, REQUEST_QUESTION_BY_USERNAME} from '../constants/actionTypes';
import {fetchQuestions, fetchQuestionByUsername} from './apiRequests/question';
import {getStudentUsername} from './selectors';

export function* getQuestions() {
    yield takeEvery(`${REQUEST_QUESTIONS}`, function* (params: {type: string; questionType: string; ids: number[]}) {
        const getQuestionsResponse = yield call(fetchQuestions, params.ids, params.questionType);

        if (getQuestionsResponse.error) {
            yield put({
                type: `${REQUEST_QUESTIONS}_ERROR`,
                message: getQuestionsResponse.error,
                ...getQuestionsResponse,
            });
        } else {
            yield put({
                type: `${REQUEST_QUESTIONS}_SUCCESS`,
                questions: getQuestionsResponse,
                questionType: params.questionType,
            });
        }
    });
}

export function* getSecurityQuestionByUsername() {
    console.log("getSecurityQuestionByUsername saga")
    yield takeEvery(`${REQUEST_QUESTION_BY_USERNAME}`, function* (params: {type: string;}) {
        const username = yield select(getStudentUsername)
        const getQuestionsResponse = yield call(fetchQuestionByUsername, username);
        if (getQuestionsResponse.error) {
            yield put({
                type: `${REQUEST_QUESTIONS}_ERROR`,
                message: getQuestionsResponse.error,
                ...getQuestionsResponse,
            });
        } else {
            yield put({
                type: `${REQUEST_QUESTIONS}_SUCCESS`,
                questions: getQuestionsResponse,
                questionType: 'securityQuestions',
            });
        }
    });
}