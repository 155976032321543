import {takeLatest, put, call, select} from 'redux-saga/effects';
import {LOAD_RESULTS, LOAD_DIMENSION_RESULTS, LOAD_DIMENSION_OBSERVATION_RESULTS} from './../constants/actionTypes';
import {fetchResults, fetchDimensionResults, fetchDimensionObservationResults} from './apiRequests/result';
import {getSurveyId} from './selectors';

export function* getResultsSaga() {
    yield takeLatest(LOAD_RESULTS, function* (params: {type: string}) {
        const surveyId = yield select(getSurveyId);
        const result = yield call(fetchResults, surveyId);
        if (result.message) {
            yield put({type: `${LOAD_RESULTS}_ERROR`, message: result.message});
        } else {
            yield put({type: `${LOAD_RESULTS}_SUCCESS`, result});
        }
    });
}

export function* getDimensionResultsSaga() {
    yield takeLatest(LOAD_DIMENSION_RESULTS, function* (params: {type: string; dimensionId: string}) {
        const surveyId = yield select(getSurveyId);
        const result = yield call(fetchDimensionResults, surveyId, params.dimensionId);
        if (result.message) {
            yield put({type: `${LOAD_RESULTS}_ERROR`, message: result.message});
        } else {
            yield put({type: `${LOAD_RESULTS}_SUCCESS`, result});
        }
    });
}

export function* getDimensionObservationResultsSaga() {
    yield takeLatest(LOAD_DIMENSION_OBSERVATION_RESULTS, function* (params: {type: string; dimensionId: string}) {
        const surveyId = yield select(getSurveyId);
        const result = yield call(fetchDimensionObservationResults, surveyId, params.dimensionId);
        if (result.message) {
            yield put({type: `${LOAD_RESULTS}_ERROR`, message: result.message});
        } else {
            yield put({type: `${LOAD_RESULTS}_SUCCESS`, result});
        }
    });
}
