import {REQUEST_SET_DEMOGRAPHIC_QUESTIONS, LOAD_STUDENT, LOGOUT_USER} from './../../constants/actionTypes';

interface ActionInterface {
    type: string;
    message?: string;
    requestInProgress: boolean;
    success: null | boolean;
    demography: {[key: string]: string};
}

interface DemographicQuestionsStateInterface {
    requestInProgress: boolean;
    success: boolean | null;
    successSetDemographicQuestions: boolean | null;
    message: null;
    answers: {[key: string]: string};
}

const initialState: DemographicQuestionsStateInterface = {
    requestInProgress: false,
    success: null,
    message: null,
    successSetDemographicQuestions: null,
    answers: {},
};

export default function demographicQuestionsReducer(
    state = initialState,
    action: ActionInterface
): DemographicQuestionsStateInterface {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case `${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}`:
            return {
                ...state,
                requestInProgress: true,
            };
        case `${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}_SUCCESS`:
            return {
                ...state,
                requestInProgress: false,
                successSetDemographicQuestions: true,
                answers: {
                    ...state.answers,
                    ...action.demography,
                },
            };
        case `${REQUEST_SET_DEMOGRAPHIC_QUESTIONS}_ERROR`:
            return {
                ...state,
                requestInProgress: false,
                successSetDemographicQuestions: false,
            };
        case `${LOAD_STUDENT}_SUCCESS`:
            return {
                ...state,
                requestInProgress: false,
                answers: {
                    ...state.answers,
                    ...action.demography,
                },
            };
        default:
            return state;
    }
}
