import React from 'react';
import styles from './styles/textInput.module.scss';
import {TextField, FormHelperText} from '@material-ui/core';

interface TextInputPropsInterface {
    type: string;
    value: string;
    label?: string;
    name?: string;
    id?: string;
    disabled?: boolean | undefined;
    error?: null | string;
    onInputChange?: Function;
    errorIcon?: boolean | undefined;
    onIconClick?: Function;
    altIncorrect?: string | undefined;
    altCorrect?: string | undefined;
    inputProps?: Object;
		helperText?: string;
		inputClassName?: string;
		containerClassName?: string;
		required?: boolean;
}

export class TextInput extends React.Component<TextInputPropsInterface, {}> {
    private onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onInputChange) {
            this.props.onInputChange(event);
        }
    };

    private onIconClick = () => {
        if (this.props.onIconClick) {
            this.props.onIconClick();
        }
    };

    private renderEyeIcon = () => {
        if (this.props.errorIcon) {
            if (this.props.error) {
                return (
                    <img
                        className={styles.eyeIcon}
                        alt={this.props.altIncorrect}
                        src={require('./../../../images/auth/error-outline.svg')}
                    />
                );
            } else {
                if (this.props.type === 'password') {
                    return (
                        <img
                            onClick={this.onIconClick}
                            className={styles.eyeIcon}
                            alt={this.props.altCorrect}
                            src={require('./../../../images/icons/visibility.svg')}
                        />
                    );
                } else {
                    return (
                        <img
                            onClick={this.onIconClick}
                            className={styles.eyeIcon}
                            alt={this.props.altCorrect}
                            src={require('./../../../images/auth/visibility-off.svg')}
                        />
                    );
                }
            }
        }

        return null;
    };

    render() {
    	const {
    		disabled,
		    name,
		    type,
		    inputClassName,
		    label,
		    inputProps,
		    helperText,
		    error,
		    value,
		    required,
		    containerClassName,
		    id,
    	} = this.props;
        return (
            <div
	            className={`${styles.inputTextContainer} ${styles.inputDesktopStylesContainer} ${containerClassName || ''}`}
            >
                <TextField
                    disabled={disabled}
                    name={name}
                    type={type}
                    className={`${styles.inputContainer} ${inputClassName || ''}`}
                    onChange={this.onInputChange}
                    value={value}
                    id={id || "standard-basic"}
                    autoComplete="off"
                    label={label}
                    inputProps={inputProps}
                    required={required}
                />
	              {helperText && <FormHelperText className={styles.inputHelperText}>{helperText}</FormHelperText>}
                {this.renderEyeIcon()}
                {error ? <div className={styles.inputError}>{error}</div> : null}
            </div>
        );
    }
}
