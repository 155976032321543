import {LOAD_SURVEY_DATA, REQUEST_SURVEY_DATA, LOAD_SURVEY_RESULT_DATA, REQUEST_SURVEY_DATA_ANYWAY} from '../constants/actionTypes';

export function loadSurveyData(slug: string) {
    return {
        type: LOAD_SURVEY_DATA,
        slug,
    };
}

export function getSurveyData(params: {slug: string}) {
    return {
        type: REQUEST_SURVEY_DATA,
        ...params,
    };
}

export function getSurveyDataAnyway(params: {slug: string}) {
    return {
        type: REQUEST_SURVEY_DATA_ANYWAY,
        ...params,
    };
}

export function getSurveyResultData() {
    return {
        type: LOAD_SURVEY_RESULT_DATA,
    };
}
