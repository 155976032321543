import React from 'react';
import styles from './styles/Terms.module.scss';
import {ArrowButton} from './../common/buttons/arrowButton';
import {MainButton} from './../common/buttons/mainButton';
import {Back} from './../common/navigation/back';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {MAX_MOBILE_DIMENSION, MIN_IPAD_WIDTH} from './../constants/dimensions';
import {RootStateInterface} from '../reducers/rootReducer';

interface TermsProps extends RouteComponentProps<{slug: string}> {
    terms?: string;
    width: number;
    height: number;
    survey?: {
        terms: string;
    };
}

export class Terms extends React.Component<TermsProps, {}> {
    private goNextStep = () => {
        this.props.history.push(`/${this.props.match.params.slug}/register/username`);
    };
    private renderGotItButton = () => {
        // 400 - fix height issue
        if (this.props.width >= MIN_IPAD_WIDTH && this.props.height >= 400) {
            return <MainButton isDefaultActive={true} onButtonClick={this.goNextStep} text="I Agree" />;
        } else if (this.props.width < MAX_MOBILE_DIMENSION || this.props.height < MAX_MOBILE_DIMENSION) {
            return <ArrowButton text="I Agree" onButtonClick={this.goNextStep} />;
        }
    };

    render() {
        return (
            <div className={styles.tabletBg}>
                <div className={styles.subContainer}>
                    <div className={styles.container}>
                        <div className={styles.containerBack}>
                            <Back
                                onNavigationClick={() =>
                                    this.props.history.push(`/${this.props.match.params.slug}/hello`)
                                }
                            />
                        </div>
                        <div className={styles.termsTitle}>Informed Assent</div>
                        <div className={styles.termTextContainer}>
                            <div
                                className={styles.termText}
                                dangerouslySetInnerHTML={{__html: this.props.terms ? this.props.terms : ''}}
                            ></div>
                        </div>
                        <div className={styles.buttonContainer}>{this.renderGotItButton()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        terms: state.survey.terms,
        width: state.window.width,
        height: state.window.height,
    };
};

const TermsWithRouter = withRouter(Terms);

export default connect(mapStateToProps, null)(TermsWithRouter);
