import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {getSurveyTemplate} from './../actions/surveyTemplateActions';
import {loginUsingAccessToken} from './../actions/authActions';
import {loadSurveyData} from './../actions/surveyActions';
import {RootStateInterface} from '../reducers/rootReducer';

interface ContainerJourneyPropsInterface extends RouteComponentProps<{slug?: string}> {
    loginUserSuccess: boolean | null;
    registerSuccess: boolean | null;
    children?: any;
    surveyTemplateId: string | null;
    loaded: boolean;
    getSurveyTemplate: Function;
    loginUsingAccessToken: Function;
    loadSurveyData: Function;
}

interface MorningQuestionInterface {
    textEn: string;
    category: string;
    questionType: string;
    id: number;
}

export class ContainerJourney extends React.Component<ContainerJourneyPropsInterface, {}> {
    componentDidMount() {
        if (this.props.surveyTemplateId && this.props.loginUserSuccess) {
            this.props.getSurveyTemplate({
                surveyTemplateId: this.props.surveyTemplateId,
            });
        } else if (!this.props.surveyTemplateId || !this.props.loginUserSuccess) {
            this.props.loginUsingAccessToken();
        }
    }

    componentDidUpdate(prevProps: ContainerJourneyPropsInterface) {
        if (this.props.loginUserSuccess) {
            this.props.loadSurveyData({slug: this.props.match.params.slug});
            if (!prevProps.loaded) {
                this.props.getSurveyTemplate({
                    surveyTemplateId: this.props.surveyTemplateId,
                });
            }
        }

        if (this.props.loginUserSuccess === false) {
            this.props.history.push(`/${this.props.match.params.slug}`);
        }
    }

    render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<{
        type: string;
        surveyTemplateId?: string;
    }>
) => ({
    getSurveyTemplate(params: {surveyTemplateId: string}) {
        return dispatch(getSurveyTemplate(params));
    },
    loginUsingAccessToken() {
        return dispatch(loginUsingAccessToken());
    },
    loadSurveyData(slug: string) {
        return dispatch(loadSurveyData(slug));
    },
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        loginUserSuccess: state.auth.success,
        registerSuccess: state.auth.registerSuccess,
        surveyTemplateId: state.survey.surveyTemplateId,
        loaded: state.surveyTemplate.loaded,
    };
};

const ContainerJourneyWithRouter = withRouter(ContainerJourney);

export default connect(mapStateToProps, mapDispatchToProps)(ContainerJourneyWithRouter);
