import {REQUEST_SURVEY_DATA, LOAD_SURVEY, LOAD_STUDENT} from '../../constants/actionTypes';

interface ActionInterface {
    id: string;
    type: string;
    termsEn: string;
    message: string;
    schoolId: string;
    requestInProgress: boolean;
    success: null | boolean;
    surveyTemplateId: string;
    startDate: string | null;
    afternoonStartDate: string | null;
    endDate: string | null;
    showResultsDate: string | null;
    slug: string | null;
    activeSurveyId: string | null;
}

interface SurveyStateInterface {
    id: string | null;
    terms: string | null;
    schoolId: string | null;
    requestInProgress: boolean;
    success: boolean | null;
    message: string | null;
    surveyTemplateId: string | null;
    startDate: string | null;
    afternoonStartDate: string | null;
    endDate: string | null;
    showResultsDate: string | null;
    slug: string | null;
}

const initialState: SurveyStateInterface = {
    id: null,
    terms: null,
    schoolId: null,
    surveyTemplateId: null,
    requestInProgress: false,
    success: null,
    message: null,
    startDate: null,
    afternoonStartDate: null,
    endDate: null,
    showResultsDate: null,
    slug: null,
};

export default function surveyReducer(state = initialState, action: ActionInterface): SurveyStateInterface {
    switch (action.type) {
        case `${REQUEST_SURVEY_DATA}`:
            return {
                ...state,
                requestInProgress: true,
            };
        case `${REQUEST_SURVEY_DATA}_SUCCESS`:
        case `${LOAD_SURVEY}_SUCCESS`:
            const {type, termsEn, ...data} = action;
            return {
                ...state,
                ...data,
                requestInProgress: false,
                success: true,
                terms: termsEn,
            };
        case `${REQUEST_SURVEY_DATA}_ERROR`:
        case `${LOAD_SURVEY}_ERROR`:
            return {
                ...state,
                requestInProgress: false,
                success: false,
                message: action.message,
            };
        case `${LOAD_STUDENT}_SUCCESS`:
            return {
                ...state,
                requestInProgress: false,
                success: true,
                id: action.activeSurveyId,
            };
        default:
            return state;
    }
}
