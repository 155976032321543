import {put, takeEvery, call} from 'redux-saga/effects';
import {REQUEST_SURVEY_TEMPLATE} from '../constants/actionTypes';
import {fetchSurveyTemplate} from './apiRequests/surveyTemplate';

export function* getSurveyTemplate() {
    yield takeEvery(`${REQUEST_SURVEY_TEMPLATE}`, function* (params: {type: string; surveyTemplateId: string}) {
        const getDemographicQuestionsResponse = yield call(fetchSurveyTemplate, {
            ...params,
        });
        if (getDemographicQuestionsResponse.error) {
            yield put({
                type: `${REQUEST_SURVEY_TEMPLATE}_ERROR`,
                ...getDemographicQuestionsResponse,
                message: getDemographicQuestionsResponse.error,
            });
        } else {
            yield put({
                type: `${REQUEST_SURVEY_TEMPLATE}_SUCCESS`,
                id: getDemographicQuestionsResponse.id,
                demographyQuestionIds: getDemographicQuestionsResponse.demographyQuestionIds,
                skipReasonQuestionId: getDemographicQuestionsResponse.skipReasonQuestionId,
                morningQuestionIds: getDemographicQuestionsResponse.morningQuestionIds,
                afternoonQuestionIds: getDemographicQuestionsResponse.afternoonQuestionIds,
                subQuestionIds: getDemographicQuestionsResponse.subQuestionIds,
            });
        }
    });
}
