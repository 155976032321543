import {REQUEST_PINNED_QUESTIONS, UPDATE_PINNED_QUESTIONS} from '../../constants/actionTypes';

interface PinnedQuestionsStateInterface {
    [key: string]: number[];
}

interface ActionInterface {
    type: string;
    pinnedQuestions: number[];
    dimension: string;
}

const initialState: PinnedQuestionsStateInterface = {};

export default function (state = initialState, action: ActionInterface): PinnedQuestionsStateInterface {
    switch (action.type) {
        case `${REQUEST_PINNED_QUESTIONS}_SUCCESS`:
        case `${UPDATE_PINNED_QUESTIONS}_SUCCESS`:
            return {
                ...state,
                [action.dimension]: action.pinnedQuestions,
            };
        default:
            return state;
    }
}
