import React, {Dispatch} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import styles from './styles/ResetPassword.module.scss';
import {TextField} from '@material-ui/core';
import {MIN_IPAD_WIDTH, MIN_IPAD_HEIGHT} from './../constants/dimensions';
import {connect} from 'react-redux';
import {loginUser, resetPassword} from '../actions/authActions';
import {loadSurveyData} from '../actions/surveyActions';
import {RootStateInterface} from '../reducers/rootReducer';
import {persistor} from '../store';
import {LightMainButton} from './../common/buttons/lightMineButton';

interface ResetPasswordProps extends RouteComponentProps<{slug: string}> {
    width: number;
    height: number;
    setNewPassword: Function;
    recoveryPasswordHash: string | null;
    resetPasswordSuccess: boolean | null;
    error: string | null;
    success: boolean | null;
    username: string | null;
    loginUser: Function;
    loadSurveyData: Function;
}

export class ResetPassword extends React.Component<
    ResetPasswordProps,
    {
        password: string;
        inputType: string;
    }
> {
    constructor(props: ResetPasswordProps) {
        super(props);

        this.state = {
            password: '',
            inputType: 'password',
        };

        if (!this.props.username) {
            this.props.history.push(`/${this.props.match.params.slug}`);
        }
    }

    componentWillReceiveProps(nextProps: ResetPasswordProps) {
        if (nextProps.success) {
            this.props.loadSurveyData(this.props.match.params.slug);
            this.props.history.push(`/${this.props.match.params.slug}/journey`);
        }
    }

    async componentDidUpdate() {
        if (this.props.resetPasswordSuccess) {
            try {
                await persistor.purge();
            } catch (e) {
                console.error(e);
            }
            this.props.loginUser({username: this.props.username, password: this.state.password});
        }
    }

    private onButtonClick = (number: Number) => {
        const password = this.state.password + number;
        if (this.state.password.length < 4) {
            this.setState({
                password: password,
            });
        } else {
            this.setState({
                password: `${number}`,
            });
        }
    };

    private switchInputType = () => {
        if (this.state.inputType === 'text') {
            this.setState({inputType: 'password'});
        } else {
            this.setState({inputType: 'text'});
        }
    };

    private renderEyeIcon = () => {
        if (this.state.inputType === 'text') {
            return (
                <img
                    onClick={this.switchInputType}
                    className={styles.eyeIcon}
                    alt={''}
                    src={require('./../../images/icons/visibility-off-dark.svg')}
                />
            );
        } else {
            return (
                <img
                    onClick={this.switchInputType}
                    className={styles.eyeIcon}
                    alt={''}
                    src={require('./../../images/icons/visibility-on-dark.svg')}
                />
            );
        }
    };

    saveNewPassword = () => {
        this.props.setNewPassword(this.props.recoveryPasswordHash, this.state.password);
    };

    protected onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!isNaN(+e.target.value)) {
            if (this.state.password.length < 4) {
                this.setState({
                    password: e.target.value,
                });
            } else {
                this.setState({
                    password: '',
                });
            }
        }
    };

    private renderIpadView = () => {
        const that = this;
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <div className={styles.passwordContainer}>
                        <div className={styles.titleContainer}>
                            Please enter <br />
                            a 4-digit <br />
                            password:
                        </div>
                        <div className={styles.inputContainer}>
                            <TextField
                                type={this.state.inputType}
                                disabled={'ontouchstart' in window}
                                className={styles.inputContainer}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onInputChange(e)}
                                id="standard-basic"
                                value={this.state.password}
                                label={'Enter your password'}
                            />
                            {this.renderEyeIcon()}
                        </div>
                    </div>
                    <div>
                        <div className={styles.buttonContainer}>
                            {Array.apply(0, Array(9)).map(function (x, number) {
                                const actualNumber = number + 1;
                                return (
                                    <div
                                        onClick={() => that.onButtonClick(actualNumber)}
                                        className={styles.number}
                                        key={actualNumber}
                                    >
                                        <span>{actualNumber}</span>
                                    </div>
                                );
                            })}
                            <div></div>
                            <div onClick={(e) => that.onButtonClick(0)} className={styles.number}>
                                <span>0</span>
                            </div>
                            <div onClick={() => this.setState({password: ''})}>
                                <img src={require('./../../images/icons/del-dark.svg')} alt="delete button" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.containerButton}>
                    <LightMainButton
                        onButtonClick={this.saveNewPassword}
                        isDefaultActive={this.state.password.length === 4}
                        text="Save"
                    />
                </div>
            </div>
        );
    };

    render() {
        const that = this;
        if (this.props.width >= MIN_IPAD_WIDTH && this.props.height >= MIN_IPAD_HEIGHT) {
            return this.renderIpadView();
        }
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <div className={styles.containerTitle}>
                        <div className={styles.titleContainer}>
                            Now you can set <br />
                            your new password:
                        </div>
                    </div>
                    <div>
                        <div className={styles.buttonContainer}>
                            {Array.apply(0, Array(9)).map(function (x, number) {
                                const actualNumber = number + 1;
                                return (
                                    <div
                                        onClick={() => that.onButtonClick(actualNumber)}
                                        className={styles.number}
                                        key={actualNumber}
                                    >
                                        <span>{actualNumber}</span>
                                    </div>
                                );
                            })}
                            <div></div>
                            <div onClick={(e) => that.onButtonClick(0)} className={styles.number}>
                                <span>0</span>
                            </div>
                            <div onClick={() => this.setState({password: ''})}>
                                <img src={require('./../../images/icons/del-dark.svg')} alt="delete button" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.passwordContainer}>
                        <div className={styles.inputContainer}>
                            <TextField
                                type={this.state.inputType}
                                disabled={true}
                                className={styles.inputContainer}
                                onChange={(e) => this.setState({password: e.target.value})}
                                id="standard-basic"
                                value={this.state.password}
                                label={'Enter your password'}
                            />
                            {this.renderEyeIcon()}
                        </div>
                    </div>
                    <div className={styles.containerButton}>
                        <div
                            className={`${styles.button} ${
                                this.state.password.length === 4 ? styles.buttonActive : ''
                            }`}
                            onClick={this.saveNewPassword}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        width: state.window.width,
        height: state.window.height,
        recoveryPasswordHash: state.auth.recoveryPasswordHash,
        resetPasswordSuccess: state.auth.resetPasswordSuccess,
        success: state.auth.success,
        error: state.auth.error,
        username: state.auth.username,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<{type: string}>) => ({
    setNewPassword(hash: string, newPassword: string) {
        return dispatch(resetPassword({hash, newPassword}));
    },
    loginUser(params: {type?: string; username?: string; password?: Number}) {
        return dispatch(loginUser(params));
    },
    loadSurveyData(slug: string) {
        return dispatch(loadSurveyData(slug));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
