const minCheckMorningId = 601;
const maxCheckMorningId = 612;
const minCheckAfternoonId = 701;
const maxCheckAfternoonId = 712;

export function getCheckMorningQuestionIds() {
    const ids: number[] = [];

    for (let i = minCheckMorningId; i < maxCheckMorningId; i++) {
        ids.push(i);
    }
    return ids;
}

export function getCheckAfternoonQuestionIds() {
    const ids: number[] = [];

    for (let i = minCheckAfternoonId; i < maxCheckAfternoonId; i++) {
        ids.push(i);
    }
    return ids;
}
