import axios from 'axios';

export function fetchResults(surveyId: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/results/${surveyId}`);
}

export function fetchDimensionResults(surveyId: string, dimensionId: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/results/${surveyId}/dimension/${dimensionId}`);
}

export function fetchDimensionObservationResults(surveyId: string, dimensionId: string) {
    return axios.get(`${process.env.REACT_APP_API_HOST}/results/${surveyId}/dimension/${dimensionId}/questions`);
}
