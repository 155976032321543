import React from 'react';
import './../styles/App.css';
import './../styles/importer.scss';
import Routes from './Routes';

export class App extends React.Component {
    componentDidMount() {
        const bodyElement = document.querySelector('body');
        if (bodyElement) {
            bodyElement.style.backgroundImage = 'none';
        }
    }

    render() {
        return <Routes />;
    }
}

export default App;
