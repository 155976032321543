import React from 'react';
import validate from 'validate.js';

export interface AbstractLoginInterface {
    username: {
        presence: {
            message: string;
        };
        length: {
            minimum: Number;
            tooShort: string;
        };
        format: {
            pattern: string;
            message: string;
        };
    };
}

export default abstract class AbstractLogin<P extends {}, S extends {}> extends React.Component<any, any> {
    private constrains: AbstractLoginInterface;
    constructor(props: {}) {
        super(props);

        this.constrains = {
            username: {
                presence: {
                    message: 'is required',
                },
                format: {
                    pattern: '[a-zA-Z0-9]+',
                    message:
                        '^You have entered username in the invalid format. Please check the username and try again.',
                },

                length: {
                    minimum: 5,
                    tooShort: 'needs to have minimum 5 characters or more',
                },
            },
        };
    }

    protected validate = (username: string | undefined) => {
        let errors;
        if (username !== undefined && username !== null) {
            if (username && username.length > 0) {
                errors = validate(
                    {
                        username: username,
                    },
                    this.constrains
                );
            } else if (username.length === 0) {
                errors = {username: ['Username cannot be empty, please enter at least 5 characters']};
            }
        }

        return errors;
    };

    render() {
        return this.props.children;
    }
}
