import React from 'react';
import styles from './styles/back.module.scss';
import {spaceKeyCode} from '../../constants/keyboard';

interface TextInputPropsInterface {
    onNavigationClick: Function;
}

export class Back extends React.Component<TextInputPropsInterface, {}> {
    onClick = () => {
        this.props.onNavigationClick()
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // The action button is activated by space on the keyup event, but the
        // default action for space is already triggered on keydown. It needs to be
        // prevented to stop scrolling the page before activating the button.
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.onClick();
        }
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.onClick();
        }
    }

    render() {
        return (<div 
                    onClick={this.onClick} 
                    onKeyDown={this.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    className={styles.backContainer}
                    tabIndex={0}
                    role="button"
                    >
                Back
            </div>
        )
    }
}