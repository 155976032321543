import React, {Dispatch} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {clearErrors, loginUser} from './../../actions/authActions';
import {loadSurveyData} from '../../actions/surveyActions';
import Password from './../../components/auth/Password';
import styles from './styles/LoginAuth.module.scss';
import {RootStateInterface} from 'js/reducers/rootReducer';
import {persistor} from '../../store';

interface LoginPasswordPropsInterface extends RouteComponentProps<{slug: string}> {
    username: string | null;
    error: string | null;
    success: boolean | null;
    loginUser: Function;
    clearErrors: Function;
    loadSurveyData: Function;
}

export class LoginPassword extends React.Component<LoginPasswordPropsInterface, {}> {
    componentDidMount() {
        if (this.props.success) {
            this.props.history.push(`/${this.props.match.params.slug}/journey`);
        }

        if (!this.props.username) {
            this.props.history.push(`/${this.props.match.params.slug}/hello`);
        }
    }

    componentWillReceiveProps(nextProps: LoginPasswordPropsInterface) {
        if (nextProps.success) {
            this.props.loadSurveyData(this.props.match.params.slug);
            this.props.history.push(`/${this.props.match.params.slug}/journey`);
        }
    }

    protected saveLoginPassword = async (creds: {password: string}) => {
        try {
            await persistor.purge();
        } catch (e) {
            console.error(e);
        }

        this.props.loginUser({username: this.props.username, password: creds.password});
    };

    render() {
        return (
            <div className={styles.containerPassword}>
                <Password
                    disabled={'ontouchstart' in window}
                    navigateToPrevStep={() =>
                        this.props.history.push(`/${this.props.match.params.slug}/login/username`)
                    }
                    navigateToRecoveryPassword={() =>
                        this.props.history.push(`/${this.props.match.params.slug}/recovery-password`)
                    }
                    saveLoginPassword={this.saveLoginPassword}
                    error={this.props.error}
                    clearErrors={this.props.clearErrors}
                />
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        success: state.auth.success,
        error: state.auth.error,
        username: state.auth.username,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<{type: string; username?: string; password?: Number}>) => ({
    loginUser(params: {type?: string; username?: string; password?: Number}) {
        return dispatch(loginUser(params));
    },
    clearErrors() {
        return dispatch(clearErrors());
    },
    loadSurveyData(slug: string) {
        return dispatch(loadSurveyData(slug));
    },
});

const LoginPasswordWithRouter = withRouter(LoginPassword);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPasswordWithRouter);
