export const LOGIN_USER = 'LOGIN_USER';
export const REQUEST_SURVEY_DATA = 'REQUEST_SURVEY_DATA';
export const REQUEST_SURVEY_DATA_ANYWAY = 'REQUEST_SURVEY_DATA_ANYWAY';
export const REGISTER_USER = 'REGISTER_USER';
export const REQUEST_SET_DEMOGRAPHIC_QUESTIONS = 'REQUEST_SET_DEMOGRAPHIC_QUESTIONS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USING_ACCESS_TOKEN = 'LOGIN_USING_ACCESS_TOKEN';
export const REQUEST_QUESTIONS = 'REQUEST_QUESTIONS';
export const REQUEST_SURVEY_TEMPLATE = 'REQUEST_SURVEY_TEMPLATE';
export const SET_QUESTIONS_MODE = 'SET_QUESTIONS_MODE';
export const SET_BUBBLE_NUMBER = 'SET_BUBBLE_NUMBER';
export const SET_QUESTIONS_NUMBER = 'SET_QUESTIONS_NUMBER';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const SET_BUBBLE_DONE = 'SET_BUBBLE_DONE';
export const SKIP_QUESTIONS_NUMBER = 'SKIP_QUESTIONS_NUMBER';
export const LOAD_SURVEY_DATA = 'LOAD_SURVEY_DATA';
export const LOAD_SURVEY_RESULT_DATA = 'LOAD_SURVEY_RESULT_DATA';
export const LOAD_ANSWERS = 'LOAD_ANSWERS';
export const LOAD_STUDENT = 'LOAD_STUDENT';
export const LOAD_SURVEY = 'LOAD_SURVEY';
export const SAVE_BUBBLES = 'SAVE_BUBBLES';
export const SAVE_SUB_QUESTIONS_ANSWER = 'SAVE_SUB_QUESTIONS_ANSWER';
export const LOAD_RESULTS = 'LOAD_RESULTS';
export const LOAD_DIMENSION_RESULTS = 'LOAD_DIMENSION_RESULTS';
export const LOAD_DIMENSION_OBSERVATION_RESULTS = 'LOAD_DIMENSION_OBSERVATION_RESULTS';
export const ON_RESOLUTION_CHANGE = 'ON_RESOLUTION_CHANGE';
export const SAVE_SECURITY_QUESTION = 'SAVE_SECURITY_QUESTION';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const SWITCH_DARKMODE = 'SWITCH_DARKMODE';
export const REQUEST_PINNED_QUESTIONS = 'REQUEST_PINNED_QUESTIONS';
export const UPDATE_PINNED_QUESTIONS = 'UPDATE_PINNED_QUESTIONS';
export const REQUEST_QUESTION_BY_USERNAME = 'REQUEST_QUESTION_BY_USERNAME';
export const USERNAME_EXIST = 'USERNAME_EXIST';
export const SET_USERNAME = 'SET_USERNAME'
export const USERNAME_EXIST_CLEAR = `USERNAME_EXIST_CLEAR`