import {MIN_IPAD_HEIGHT, MIN_IPAD_WIDTH} from './../constants/dimensions';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import {Button} from '@material-ui/core';
import React from 'react';
import {RootStateInterface} from '../reducers/rootReducer';
import {connect} from 'react-redux';
import {spaceKeyCode} from '../constants/keyboard';
import styles from './styles/Hello.module.scss';

interface HelloProps extends RouteComponentProps<{slug: string}> {
    width: number;
    height: number;
}

export class Hello extends React.Component<HelloProps, {}> {

    startWalkthroughHandler = () => {
        this.props.history.push(`/${this.props.match.params.slug}/terms`)
    }

    continueWalkthroughHandler = () => {
        this.props.history.push(`/${this.props.match.params.slug}/login/username`)
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, type:string) => {
        // The action button is activated by space on the keyup event, but the
        // default action for space is already triggered on keydown. It needs to be
        // prevented to stop scrolling the page before activating the button.
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (type === 'start') {
                this.startWalkthroughHandler();
            } else {
                this.continueWalkthroughHandler();
            }
        }
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLSpanElement>, type:string) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            if (type === 'start') {
                this.startWalkthroughHandler();
            } else {
                this.continueWalkthroughHandler();
            }
        }
    }
    
    private renderButtons = () => {
        if (this.props.width >= MIN_IPAD_WIDTH && this.props.height >= MIN_IPAD_HEIGHT) {
            return (
                <div className={styles.helloContainerButtons}>
                    <div className={styles.helloNewButton}>
                        <span 
                            onClick={() => this.startWalkthroughHandler()}
                            onKeyDown={(e) => this.onKeyDown(e, 'start')}
                            onKeyUp={(e) => this.onKeyUp(e, 'start')}
                            tabIndex={0} 
                            role="button">
                            Start a new
                            <br />
                            Walkthrough
                        </span>
                    </div>
                    <div className={styles.helloContinueButton}>
                        <span
                            onClick={() => this.continueWalkthroughHandler()}
                            onKeyDown={(e) => this.onKeyDown(e, 'continue')}
                            onKeyUp={(e) => this.onKeyUp(e, 'continue')}
                            tabIndex={0} 
                            role="button"
                        >
                            Continue <br />
                            current <br />
                            Walkthrough
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.helloContainerButtons}>
                <Button
                    onClick={() => this.props.history.push(`/${this.props.match.params.slug}/terms`)}
                    variant="contained"
                    className={styles.helloButton}
                >
                    Start a new Walkthrough
                </Button>
                <Button
                    onClick={() => this.props.history.push(`/${this.props.match.params.slug}/login/username`)}
                    variant="contained"
                    className={styles.helloButton}
                >
                    Continue current Walkthrough
                </Button>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.helloBlock}>
                        <div className={styles.positionBlock}>
                            <div className={styles.helloTitle}>
                                What would <br />
                                you like to do?
                            </div>
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state: RootStateInterface) {
    return {
        width: state.window.width,
        height: state.window.height,
    };
};

const HelloWithRouter = withRouter(Hello);

export default connect(mapStateToProps)(HelloWithRouter);
