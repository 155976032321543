import React from 'react';

export class ClickOutside extends React.PureComponent<{
    handleFunc: Function;
}> {
    private container: HTMLElement | null;
    constructor(props: {handleFunc: Function}) {
        super(props);

        this.container = null;
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillMount() {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick(e: MouseEvent) {
        // Check, if user clicked outside the container
        if (this.props.handleFunc && this.container && !this.container.contains(e.target as Node)) {
            this.props.handleFunc();
        }
    }

    render() {
        return (
            <div
                ref={(e) => {
                    this.container = e;
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
