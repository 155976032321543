import {REQUEST_QUESTIONS, LOGOUT_USER} from './../../constants/actionTypes';
import {DemographicQuestionInterface, QuestionWithOptionsInterface, QuestionDataInterface} from '../../interfaces';

interface QuestionStateInterface {
    requestInProgress: {
        demographyQuestions: boolean;
        skipReasonQuestion: boolean;
        morningQuestions: boolean;
        afternoonQuestions: boolean;
        securityQuestions: boolean;
    };
    demographyQuestions: DemographicQuestionInterface[];
    skipReasonQuestion: QuestionWithOptionsInterface[] | null;
    morningQuestions: QuestionDataInterface[];
    afternoonQuestions: QuestionDataInterface[];
    securityQuestions: QuestionDataInterface[];
    success: boolean;
    message: string;
}

interface ActionInterface {
    type: string;
    questionType: 'demographyQuestions' | 'skipReasonQuestion' | 'morningQuestions' | 'afternoonQuestions';
    questions?: QuestionDataInterface[];
    message: string;
}

const initialState: QuestionStateInterface = {
    requestInProgress: {
        demographyQuestions: false,
        skipReasonQuestion: false,
        morningQuestions: false,
        afternoonQuestions: false,
        securityQuestions: false,
    },
    demographyQuestions: [],
    skipReasonQuestion: [],
    morningQuestions: [],
    afternoonQuestions: [],
    securityQuestions: [],
    success: true,
    message: '',
};

export default function (state = initialState, action: ActionInterface): QuestionStateInterface {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case REQUEST_QUESTIONS:
            return {
                ...state,
                requestInProgress: {...state.requestInProgress, [action.questionType]: true},
            };
        case `${REQUEST_QUESTIONS}_SUCCESS`:
            return {
                ...state,
                requestInProgress: {...state.requestInProgress, [action.questionType]: false},
                success: true,
                message: '',
                [action.questionType]: action.questions,
            };
        case `${REQUEST_QUESTIONS}_ERROR`:
            return {
                ...state,
                success: false,
                message: action.message,
                requestInProgress: {...state.requestInProgress, [action.questionType]: false},
            };
        default:
            return state;
    }
}
