import {
    SET_QUESTIONS_NUMBER,
    SET_BUBBLE_NUMBER,
    SET_QUESTIONS_MODE,
    SWITCH_DARKMODE,
} from './../../constants/actionTypes';

interface ConfigStateInterface {
    questionNumber: number;
    bubbleNumber: number;
    questionsMode: 'morningQuestions' | 'afternoonQuestions';
    darkmode: boolean;
}

interface ActionInterface {
    type: string;
    number: number;
    mode: 'morningQuestions' | 'afternoonQuestions';
}

const initialState: ConfigStateInterface = {
    questionNumber: 0,
    bubbleNumber: 0,
    questionsMode: 'morningQuestions',
    darkmode: false,
};

export default function (state = initialState, action: ActionInterface): ConfigStateInterface {
    switch (action.type) {
        case SET_QUESTIONS_NUMBER:
            return {
                ...state,
                questionNumber: action.number,
            };
        case SET_BUBBLE_NUMBER:
            return {
                ...state,
                bubbleNumber: action.number,
            };
        case SET_QUESTIONS_MODE:
            return {
                ...state,
                questionsMode: action.mode,
            };
        case 'persist/PURGE':
            const {darkmode, ...config} = initialState;
            return {
                ...state,
                ...config
            };
        case SWITCH_DARKMODE:
            return {
                ...state,
                darkmode: !state.darkmode,
            };
        default:
            return state;
    }
}
