import React from 'react';
import styles from './styles/Question.module.scss';
import {onKeyDown, onKeyUp} from '../../helpers/accessibility';

interface QuestionPropsInterface {
    text: string;
    isAnswered: boolean;
    handleClick: Function;
}

export default class Question extends React.Component<QuestionPropsInterface, {}> {
    onClick = () => {
        this.props.handleClick();
    }

    render() {
        return (
            <div
                className={`${styles.question} ${this.props.isAnswered ? styles.answeredQuestion : ''}`}
                onClick={this.onClick}
                onKeyUp={(e) => onKeyUp(e, this.onClick)}
                onKeyDown={(e) => onKeyDown(e, this.onClick)}
                aria-haspopup="listbox"
                role="button"
                tabIndex={0}
            >
                {this.props.text}
            </div>
        );
    }
}
