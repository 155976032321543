import {fork, put, take, takeEvery, select, call} from 'redux-saga/effects';
import {
    LOAD_SURVEY_DATA,
    LOAD_ANSWERS,
    LOAD_STUDENT,
    LOAD_SURVEY,
    REQUEST_SURVEY_DATA,
    REQUEST_SURVEY_DATA_ANYWAY,
    LOAD_SURVEY_RESULT_DATA,
} from '../constants/actionTypes';
import {fetchAnswers} from './apiRequests/answer';
import {fetchStudent} from './apiRequests/student';
import {fetchSurvey, fetchSurveyTermsData, fetchSurveyTermsAnywayData} from './apiRequests/survey';
import {getSurveyId, getStudentId, getSurveyData, getDemographyAnswers, getAnswers} from './selectors';

export function* getSurveyTermsDataSaga() {
    yield takeEvery(`${REQUEST_SURVEY_DATA}`, function* (params: {type: string; slug: string}) {
        const schoolDataResponse = yield call(fetchSurveyTermsData, {
            slug: params.slug,
        });
        if (schoolDataResponse.error || schoolDataResponse.message) {
            yield put({type: `${REQUEST_SURVEY_DATA}_ERROR`, ...schoolDataResponse});
        } else {
            yield put({type: `${REQUEST_SURVEY_DATA}_SUCCESS`, ...schoolDataResponse});
        }
    });
}

export function* getSurveyTermsDataAnywaySaga() {
    yield takeEvery(`${REQUEST_SURVEY_DATA_ANYWAY}`, function* (params: {type: string; slug: string}) {
        const schoolDataResponse = yield call(fetchSurveyTermsAnywayData, {
            slug: params.slug,
        });
        if (schoolDataResponse.error || schoolDataResponse.message) {
            yield put({type: `${REQUEST_SURVEY_DATA}_ERROR`, ...schoolDataResponse});
        } else {
            yield put({type: `${REQUEST_SURVEY_DATA}_SUCCESS`, ...schoolDataResponse});
        }
    });
}


function* loadAnswers() {
    const schoolSurveyId = yield select(getSurveyId);
    const savedAnswers = yield select(getAnswers);
    if (Object.keys(savedAnswers).length) {
        return yield put({type: 'WARNING: ANSWERS DATA WAS LOADED'});
    }
    const answers = yield call(fetchAnswers, schoolSurveyId);
    if (answers.message) {
        yield put({type: `${LOAD_ANSWERS}_ERROR`, message: answers.message});
    } else {
        yield put({type: `${LOAD_ANSWERS}_SUCCESS`, answers});
    }
}

function* loadStudent() {
    const studentId = yield select(getStudentId);
    const demographyAnswers = yield select(getDemographyAnswers);
    if (Object.keys(demographyAnswers).length > 0) {
        return yield put({type: 'WARNING: STUDENT DATA WAS LOADED'});
    }
    const student = yield call(fetchStudent, {id: studentId});
    if (student.message) {
        yield put({type: `${LOAD_STUDENT}_ERROR`, message: student.message});
    } else {
        yield put({
            type: `${LOAD_STUDENT}_SUCCESS`,
            demography: student.demography,
            bubbles: student.bubbles,
            activeSurveyId: student.activeSurveyId,
        });
    }
}

function* loadSurvey() {
    const schoolSurveyId = yield select(getSurveyId);
    const surveyData = yield select(getSurveyData);
    if (surveyData.afternoonStartDate) {
        return yield put({type: 'WARNING: SURVEY DATA WAS LOADED'});
    }
    const survey = yield call(fetchSurvey, {id: schoolSurveyId});
    if (survey.message) {
        yield put({type: `${LOAD_SURVEY}_ERROR`, message: survey.message});
    } else {
        yield put({type: `${LOAD_SURVEY}_SUCCESS`, ...survey});
    }
}

export function* watchLoadSurveyData() {
    while (true) {
        yield take(LOAD_SURVEY_DATA);
        yield fork(loadAnswers);
        yield fork(loadStudent);
        yield fork(loadSurvey);
    }
}

export function* watchLoadSurveyResultData() {
    while (true) {
        yield take(LOAD_SURVEY_RESULT_DATA);
        yield fork(loadSurvey);
    }
}

export function* watchLoadStudent() {
    while (true) {
        yield take(LOAD_STUDENT);
        yield fork(loadStudent);
    }
}
