import React from 'react';
import styles from './styles/JourneyBubble.module.scss';
import {onKeyDown, onKeyUp} from 'js/helpers/accessibility';

interface JourneyBubbleProps {
    index: number;
    isDone?: boolean;
    onClick: (index: number) => void;
}

const svgNames: string[] = ['01', '02', '03', '04', '02', '06', '01', '02', '03', '04', '02', '03'];
const svgNamesDone: string[] = [
    '01done',
    '02done',
    '03done',
    '01done',
    '04done',
    '06done',
    '01done',
    '03done',
    '04done',
    '01done',
    '02done',
    '03done',
];

export class JourneyBubble extends React.Component<JourneyBubbleProps> {
    get contentSvg() {
        return require(`../../../images/journey/${
            this.props.isDone ? svgNamesDone[this.props.index] : svgNames[this.props.index]
        }.svg`);
    }

    render() {
        const {isDone, index} = this.props;
        const bubbleStyles = [styles.bubble, styles[`bubble${index + 1}`]];
        const containerStyles = [styles.bubbleContainer, styles[`bubbleContainer${index + 1}`]];
        if (isDone) {
            containerStyles.push(styles.done);
        }

        return (
            <div className={containerStyles.join(' ')}>
                <div 
                    className={bubbleStyles.join(' ')} 
                    tabIndex={0}
                    role="button"
                    onClick={() => this.props.onClick(this.props.index)}
                    onKeyUp={(e) => onKeyUp(e, () => this.props.onClick(this.props.index))}
                    onKeyDown={(e) => onKeyDown(e, () => this.props.onClick(this.props.index))}
                 >
                    <div className={styles.backdrop}></div>
                    <div className={styles.bubbleText}>
                        <img src={this.contentSvg} alt="" />
                        <div>PART {index + 1}</div>
                    </div>
                </div>
            </div>
        );
    }
}
