import {
    CLEAR_AUTH_ERRORS,
    FORGOT_PASSWORD_REQUEST,
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    RESET_PASSWORD_REQUEST,
    SAVE_SECURITY_QUESTION,
    SET_USERNAME,
    USERNAME_EXIST,
    USERNAME_EXIST_CLEAR
} from './../../constants/actionTypes';

import decodeJwt from 'jwt-decode';

interface ActionInterface {
    type: string;
    message: string | null;
    username: string;
    id: string;
    accessToken: string;
    hash: string;
    publicDevice: boolean;
    hasUser: boolean | null;
}

interface AuthStateInterface {
    username: string | null;
    studentId: string | null;
    success: boolean | null;
    error: string | null;
    registerSuccess: boolean | null;
    registerErrorMessage: string | null;
    securityQuestionSuccess: boolean | null;
    securityQuestionErrorMessage: string | null;
    recoveryPasswordHash: string | null;
    recoveryPasswordHashSuccess: boolean | null;
    resetPasswordSuccess: boolean | null;
    publicDevice: boolean;
    hasUser: boolean | null;
}

const initialState: AuthStateInterface = {
    username: '',
    studentId: '',
    success: null,
    error: '',
    registerSuccess: null,
    registerErrorMessage: null,
    securityQuestionSuccess: null,
    securityQuestionErrorMessage: null,
    recoveryPasswordHash: null,
    recoveryPasswordHashSuccess: null,
    resetPasswordSuccess: null,
    publicDevice: false,
    hasUser: null
};

export default function authReducer(state = initialState, action: ActionInterface): AuthStateInterface {
    switch (action.type) {
        case `${SET_USERNAME}`:
            return {
                ...state,
                username: action.username,
            };
        case `${LOGIN_USER}`:
            return {
                ...state,
                success: null,
            };
        case `${LOGIN_USER}_SUCCESS`:
            if (action.accessToken) {
                const decodedToken: {sub: string} = decodeJwt(action.accessToken);

                return {
                    ...state,
                    success: true,
                    username: action.username,
                    studentId: decodedToken.sub,
                };
            }

            return {
                ...state,
                success: false,
            };
        case `${LOGIN_USER}_ERROR`:
            return {
                ...state,
                success: false,
                error: action.message,
            };
        case `${REGISTER_USER}`:
            return state;
        case `${REGISTER_USER}_SUCCESS`:
            return {
                ...state,
                registerSuccess: true,
                registerErrorMessage: '',
                username: action.username,
                studentId: action.id,
            };
        case `${REGISTER_USER}_ERROR`:
            return {
                ...state,
                registerSuccess: false,
                registerErrorMessage: action.message,
            };
        case `${CLEAR_AUTH_ERRORS}`:
            return {
                ...state,
                error: null,
                registerErrorMessage: null,
            };
        case LOGOUT_USER:
            return {
                ...state,
                success: false,
                registerSuccess: false,
                ...initialState,
                publicDevice: action.publicDevice !== undefined ? action.publicDevice : initialState.publicDevice,
            };
        case `${SAVE_SECURITY_QUESTION}_SUCCESS`: {
            return {
                ...state,
                securityQuestionSuccess: true,
            };
        }
        case `${SAVE_SECURITY_QUESTION}_ERROR`: {
            return {
                ...state,
                securityQuestionSuccess: false,
                securityQuestionErrorMessage: action.message,
            };
        }
        case `${FORGOT_PASSWORD_REQUEST}_SUCCESS`: {
            return {
                ...state,
                recoveryPasswordHash: action.hash,
                recoveryPasswordHashSuccess: true,
            };
        }
        case `${FORGOT_PASSWORD_REQUEST}_ERROR`: {
            return {
                ...state,
                recoveryPasswordHash: '',
                recoveryPasswordHashSuccess: false,
            };
        }
        case `${RESET_PASSWORD_REQUEST}_SUCCESS`: {
            return {
                ...state,
                resetPasswordSuccess: true,
            };
        }
        case `${RESET_PASSWORD_REQUEST}_ERROR`: {
            return {
                ...state,
                resetPasswordSuccess: false,
            };
        }
        case `${USERNAME_EXIST}_SUCCESS`: {
            return {
                ...state,
                hasUser: action.hasUser
            }
        }
        case USERNAME_EXIST_CLEAR: {
            return {
                ...state,
                hasUser: null
            }
        }
        default:
            return state;
    }
}
