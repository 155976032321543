import React, {Dispatch} from 'react';
import styles from './styles/Container.module.scss';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {logOut} from './../actions/authActions';
import {getSurveyData, getSurveyDataAnyway} from './../actions/surveyActions';
import {onResolutionChange} from './../actions/windowEventsActions';
import {RootStateInterface} from '../reducers/rootReducer';
import NotFound from './../pages/NotFound';
import {inactiveIntervalMc} from '../constants/publicDevice';
import Loader from '../pages/Loader';

interface MatchParams {
    slug: string;
}

interface ContainerPropsInterface extends RouteComponentProps<MatchParams> {
    children: React.ReactNode;
    getSurveyData?: Function | undefined;
    getSurveyDataAnyway: Function;
    success: boolean | null;
    schoolRequestInProgress: boolean | null;
    message: string | null;
    onResolutionChange: Function;
    darkmode: boolean;
    logOut: Function;
}

export class Container extends React.Component<ContainerPropsInterface, {}> {
    private containerElem: HTMLElement | null;
    private userRole: String | null;
    constructor(props: ContainerPropsInterface) {
        super(props);

        this.containerElem = null;
        this.userRole = localStorage.getItem('role');
    }

    componentDidMount() {
        if (this.userRole !== 'SUPER_ADMIN' && this.userRole !== 'SCHOOL_ADMIN') {
            if (this.props.getSurveyData) {
                this.props.getSurveyData({slug: this.props.match.params.slug});
            }
        } else {
            const location = this.props.location.pathname;
            if (location.indexOf('success') === -1 && location.indexOf('result') === -1) {
                this.props.logOut();
                this.props.history.push(`/not-found`);
                return;
            }

            this.props.getSurveyDataAnyway({slug: this.props.match.params.slug});
        }

        this.addEventListeners();
        this.checkLastActivity();
    }

    checkLastActivity() {
        const lastActiveAt = window.sessionStorage.getItem('lastActiveAt');
        if (lastActiveAt) {
            const lastActiveDate = new Date(lastActiveAt);
            if (new Date().getTime() - lastActiveDate.getTime() > inactiveIntervalMc) {
                this.props.logOut();
                window.sessionStorage.removeItem('lastActiveAt');
                this.props.history.push(`/${this.props.match.params.slug}/login/username`);
            }
        }
    }

    componentWillUnmount() {
        this.removeEventListeners();
    }

    addEventListeners = () => {
        window.addEventListener('orientationchange', this.orientationChanged);
        window.addEventListener('resize', this.resize);
    };

    removeEventListeners = () => {
        window.removeEventListener('orientationchange', this.orientationChanged);
        window.removeEventListener('resize', this.resize);
    };

    orientationChanged = () => {
        if (this.containerElem) {
            // Fix orientation change on safari
            this.containerElem.style.height = `initial`;
            setTimeout(() => {
                if (this.containerElem) {
                    this.containerElem.style.height = `100%`;
                }
                setTimeout(() => {
                    // this line prevents the content
                    // from hiding behind the address bar
                    window.scrollTo(0, 1);
                }, 20);
            }, 300);
        }
    };

    resize = () => {
        this.props.onResolutionChange({width: window.innerWidth, height: window.innerHeight});
    };

    renderContent = () => {
        switch (this.props.success) {
            case true:
                return (
                    <div
                        data-darkmode={this.props.darkmode}
                        className={`${styles.container} containerGlobal`}
                        ref={(e) => (this.containerElem = e)}
                    >
                        {this.props.children}
                    </div>
                );
            case false:
                return <NotFound />;
            default:
                return null;
        }
    };

    render() {
        if (this.props.schoolRequestInProgress) {
            return <Loader />;
        }

        return this.renderContent();
    }
}

const ContainerWithRouter = withRouter(Container);

const mapDispatchToProps = (dispatch: Dispatch<{type: string; slug?: string; width?: number; height?: number}>) => ({
    getSurveyData(params: {type: string; slug: string}) {
        return dispatch(getSurveyData(params));
    },
    getSurveyDataAnyway(params: {type: string; slug: string}) {
        return dispatch(getSurveyDataAnyway(params));
    },
    onResolutionChange(params: {type?: string; width: number; height: number}) {
        return dispatch(onResolutionChange(params));
    },
    logOut() {
        return dispatch(logOut({publicDevice: true}));
    },
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        success: state.survey.success,
        message: state.survey.message,
        schoolRequestInProgress: state.survey.requestInProgress,
        darkmode: state.config.darkmode,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerWithRouter);
