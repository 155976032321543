import {
    LOGIN_USER,
    SET_USERNAME,
    REGISTER_USER,
    CLEAR_AUTH_ERRORS,
    LOGOUT_USER,
    LOGIN_USING_ACCESS_TOKEN,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    USERNAME_EXIST_CLEAR
} from './../constants/actionTypes';

interface LoginUserInterface {
    username?: string;
    password?: Number;
}

export function loginUser(params: LoginUserInterface) {
    return {
        type: LOGIN_USER,
        ...params,
    };
}

export function logOut(params?: {publicDevice?: boolean}) {
    return {
        type: LOGOUT_USER,
        publicDevice: params?.publicDevice,
    };
}

export function registerUser(params: {username: string; password: Number; termsAccepted: boolean; schoolId: string}) {
    return {
        type: REGISTER_USER,
        ...params,
    };
}

export function clearErrors() {
    return {
        type: CLEAR_AUTH_ERRORS,
    };
}

export function setUsername(params: {username: string}) {
    return {
        type: SET_USERNAME,
        ...params,
    };
}

export function loginUsingAccessToken() {
    return {
        type: LOGIN_USING_ACCESS_TOKEN,
    };
}

export function forgotPassword(params: {questionId?: number; answer?: string; username: string}) {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        ...params,
    };
}

export function resetPassword(params: {hash: string; newPassword: string}) {
    return {
        type: RESET_PASSWORD_REQUEST,
        ...params,
    };
}

export function clearUserExists() {
    return {
        type: USERNAME_EXIST_CLEAR
    };
}