import {
    FORGOT_PASSWORD_REQUEST,
    LOAD_STUDENT,
    LOGIN_USER,
    LOGIN_USING_ACCESS_TOKEN,
    LOGOUT_USER,
    REGISTER_USER,
    RESET_PASSWORD_REQUEST,
    SET_USERNAME,
    USERNAME_EXIST
} from './../constants/actionTypes';
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {fetchUser, forgotPassword, getRefreshedToken, logOut, registerUser, resetPassword, userExist} from './apiRequests/auth';
import {removeDefaultAuthorizationHeader, setDefaultAuthorizationHeader} from './../middleware/defaultHeaders';

import decodeJwt from 'jwt-decode';
import {fetchStudent} from './apiRequests/student';
import {getSurveyId} from './selectors';
import {storeTokens} from './../helpers/storeTokens';

export function* setUsernameListener () {
    yield takeEvery(`${SET_USERNAME}`, function* (params: {type: string, username: string}) {
        const response: any = yield call(userExist, {
            username: params.username
        })

        if (response.error) {
            yield put({type: `${USERNAME_EXIST}_ERROR`});
        } else {
            if (response) {
                yield put({ type: `${USERNAME_EXIST}_SUCCESS`, ...response })
            } else {
                yield put({ type: `${USERNAME_EXIST}_SUCCESS`, ...response })
            }
        }
    })
}

export function* setLoginUsernameSaga() {
    yield takeEvery(`${LOGIN_USER}`, function* (params: {type: string; username: string; password: Number}) {
        const surveyId = yield select(getSurveyId);
        const loginUserResponse = yield call(fetchUser, {
            username: params.username,
            password: params.password,
            from: 'student',
            surveyId: surveyId,
        });
        if (loginUserResponse.error) {
            yield put({type: `${LOGIN_USER}_ERROR`, ...loginUserResponse});
        } else {
            storeTokens(loginUserResponse.accessToken, loginUserResponse.refreshToken);
            setDefaultAuthorizationHeader();
            yield put({type: `${LOGIN_USER}_SUCCESS`, ...loginUserResponse, username: params.username});
        }
    });
}

export function* registerUserSaga() {
    yield takeEvery(`${REGISTER_USER}`, function* (params: {
        type: string;
        username: string;
        password: Number;
        termsAccepted: boolean;
        schoolId: string;
    }) {
        const registerUserResponse = yield call(registerUser, {
            ...params,
        });
        if (registerUserResponse.error) {
            yield put({type: `${REGISTER_USER}_ERROR`, ...registerUserResponse});
        } else {
            yield put({type: `${REGISTER_USER}_SUCCESS`, ...registerUserResponse});
            yield put({type: LOGIN_USER, username: params.username, password: params.password});
        }
    });
}

export function* logOutSaga() {
    yield takeEvery(`${LOGOUT_USER}`, function* () {
        yield call(logOut);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role')
        removeDefaultAuthorizationHeader();
    });
}

export function* loginUsingAccessToken() {
    yield takeEvery(`${LOGIN_USING_ACCESS_TOKEN}`, function* () {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            const refreshTokenResponse = yield call(getRefreshedToken);
            storeTokens(refreshTokenResponse.accessToken, refreshTokenResponse.refreshToken);
            const tokenData: {sub: string} = decodeJwt(refreshTokenResponse.accessToken);
            const student = yield call(fetchStudent, {id: tokenData.sub});

            if (student.message) {
                yield put({type: `${LOAD_STUDENT}_ERROR`, message: student.message});
                yield put({type: `${LOGIN_USER}_ERROR`, message: 'Failed to logIn user'});
            } else {
                yield put({
                    type: `${LOGIN_USER}_SUCCESS`,
                    accessToken: refreshTokenResponse.accessToken,
                    username: student.username,
                });
            }
        } else {
            yield put({type: `${LOGIN_USER}_ERROR`, message: 'Failed to logIn user'});
        }
    });
}

export function* forgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, function* ({
        questionId,
        answer,
        username,
    }: {
        type: string;
        questionId: number;
        answer: string;
        username: string;
    }) {
        const response = yield call(forgotPassword, {
            questionId,
            answer,
            username,
        });

        if (response.hash) {
            yield put({
                type: `${FORGOT_PASSWORD_REQUEST}_SUCCESS`,
                hash: response.hash,
            });
        } else {
            yield put({
                type: `${FORGOT_PASSWORD_REQUEST}_ERROR`,
            });
        }
    });
}

export function* resetPasswordSaga() {
    yield takeEvery(RESET_PASSWORD_REQUEST, function* ({
        hash,
        newPassword,
    }: {
        type: string;
        hash: string;
        newPassword: string;
    }) {
        const response = yield call(resetPassword, {
            hash,
            newPassword,
        });

        if (response.message) {
            yield put({
                type: `${RESET_PASSWORD_REQUEST}_ERROR`,
            });
        } else {
            yield put({
                type: `${RESET_PASSWORD_REQUEST}_SUCCESS`,
                hash: response.hash,
            });
        }
    });
}
