import React from 'react';
import styles from './styles/checkboxInput.module.scss';
import {spaceKeyCode} from '../../constants/keyboard';

interface CheckboxInputProps {
    text: string | React.ReactNode;
    checked: boolean;
    onCheckboxChange: Function;
}

export class CheckboxInput extends React.Component<CheckboxInputProps, {}> {
    onClick = () => {
        this.props.onCheckboxChange(); 
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
            this.onClick();
        }
    }

    onKeyUp = (event:React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === spaceKeyCode) {
            event.preventDefault();
        }
    }

    render() {
        return (
            <div className={styles.checkboxContainer}>
                <div>
                    <input
                        type="checkbox"
                        className={styles.checkbox}
                        checked={this.props.checked}
                        id="checkbox"
                        onChange={() => this.props.onCheckboxChange()}
                    />
                    <label htmlFor="checkbox" className={styles.labelCheckbox} />
                </div>
                <div 
                    className={styles.checkboxPlaceholder} 
                    onClick={this.onClick} 
                    onKeyDown={this.onKeyDown} 
                    onKeyUp={this.onKeyUp} 
                    role="checkbox" 
                    aria-checked={this.props.checked}
                    tabIndex={0}
                >
                    {this.props.text}
                </div>
            </div>
        );
    }
}
