export const MAX_MOBILE_DIMENSION = 768;
export const MIN_IPAD_WIDTH = 640;
export const MIN_IPAD_HEIGHT = 640;
export const MAX_IPAD_WIDTH = 1260;
export const MAX_IPAD_HEIGHT = 1260;
export const MAX_SCORE = 4;

export const dimensions = {
    'physical-safety': {
        text: 'Physical Safety',
        details:
            "This score reflects students' ratings of how safe they feel from physical harm and if there are consistent rules.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score for Physical Safety.',
    },
    'emotional-safety': {
        text: 'Emotional Safety',
        details: "This score reflects students' ratings of how safe they feel to express their feelings and interests.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score for Emotional Safety.',
    },
    'social-safety': {
        text: 'Social Safety',
        details:
            "This score reflects students' ratings of how safe they feel from verbal abuse, teasing, and exclusion.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score Social Safety.',
    },
    'student-adult-relationships': {
        text: 'Student-Adult Relationships',
        details:
            "This score reflects students' ratings of how supportive and caring the relationships are between students and adults. For example, whether or not teachers have high expectations for students' success, listen to students and get to know them, and have a personal concern for students' problems.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score Student-Adult Relationships. ',
    },
    'student-relationships': {
        text: 'Relationships Among Students',
        details:
            "This score reflects students' ratings of how supportive and caring the students are to each other. For example, this shows whether or not students are generally friendly, welcoming, and helpful to each other.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score Relationships among Students. ',
    },
    'adult-relationships': {
        text: 'Relationships Among Adults',
        details: "This score reflects students' ratings on how well adults in the building get along with each other. ",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score Relationships among Adults. ',
    },
    'supportive-teaching-practices': {
        text: 'Effective Teaching Practices',
        details:
            "This score reflects students' ratings of your school's support for academic performance and social and emotional learning.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score for Effective Teaching Practices. ',
    },
    'support-for-diversity': {
        text: 'Respect for Diversity',
        details:
            "This score reflects students' ratings of whether or not there is respect for individual differences (like gender, race, culture, etc.) among everyone at school and if there are overall norms for tolerance.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score Respect for Diversity. ',
    },
    'school-pride': {
        text: 'School Pride',
        details:
            "This score reflects students' ratings of whether or not the school is clean and has enough resources, as well as overall positive feelings about the school including whether or not people participate in school events.",
        observations:
            'Here you can see how many students answered yes response to each of these statements, which may impact the overall score School Pride. ',
    },
};
