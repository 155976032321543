import {createStore, applyMiddleware, Store} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger';
import rootSaga from '../sagas/rootSaga';
import {RootState} from '../reducers/rootReducer';
import {setDefaultAuthorizationHeader} from './../middleware/defaultHeaders';

export default function configureStore(rootReducer: RootState) {
    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['config'],
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [];
    middleware.push(sagaMiddleware);
    if (process.env.NODE_ENV === 'development') {
        // middleware.push(logger);
    }
    const store: Store = createStore(persistedReducer, applyMiddleware(...middleware));
    setDefaultAuthorizationHeader();
    sagaMiddleware.run(rootSaga);
    let persistor = persistStore(store);
    return {store, persistor};
}
