import {MIN_IPAD_HEIGHT, MIN_IPAD_WIDTH} from './../constants/dimensions';
import React, {Dispatch} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {onKeyDown, onKeyUp} from '../helpers/accessibility';

import Backdrop from '@material-ui/core/Backdrop';
import {ClickOutside} from './../common/clickOutside';
import {DemographicQuestionInterface} from '../interfaces';
import {DemographicalRadioInput} from './../common/inputs/demographicalRadioInput';
import Fade from '@material-ui/core/Fade';
import {LightMainButton} from './../common/buttons/lightMineButton';
import Modal from '@material-ui/core/Modal';
import Question from './../components/demographic/Question';
import {RootStateInterface} from '../reducers/rootReducer';
import SelectGender from './../components/demographic/SelectGender';
import {connect} from 'react-redux';
import {getQuestions} from './../actions/questionActions';
import {logOut} from './../actions/authActions';
import {setDemographicQuestions} from './../actions/demographicActions';
import styles from './styles/ContainerDemographical.module.scss';

interface ContainerDemographicalStateInterface {
    openModal: boolean;
    answer: string;
    activeQuestion: null | DemographicQuestionInterface;
    answeredQuestions: {[key: string]: string};
}

interface ContainerDemographicalPropsInterface
    extends RouteComponentProps<{
        slug?: string;
    }> {
    demographyQuestionIds: number[];
    demographicQuestions: DemographicQuestionInterface[];
    getDemographicQuestions: Function;
    setDemographicQuestions: Function;
    successSetDemographicQuestions: boolean | null;
    loginUserSuccess: boolean | null;
    studentId: string | null;
    username: string | null;
    logOut: Function;
    demography: {[key: string]: string};
    width: number;
    height: number;
}

export class ContainerDemographical extends React.Component<
    ContainerDemographicalPropsInterface,
    ContainerDemographicalStateInterface
> {
    private refClickoutsideContainer: HTMLDivElement | null;
    constructor(props: ContainerDemographicalPropsInterface) {
        super(props);

        this.state = {
            openModal: false,
            answer: '',
            activeQuestion: null,
            answeredQuestions: props.demography || {},
        };

        this.refClickoutsideContainer = null;
    }

    componentDidMount() {
        if (!this.props.demographicQuestions.length && this.props.demographyQuestionIds.length) {
            this.props.getDemographicQuestions(this.props.demographyQuestionIds);
        }
    }

    componentWillReceiveProps(nextProps: ContainerDemographicalPropsInterface) {
        if (!this.props.demographyQuestionIds.length && nextProps.demographyQuestionIds.length) {
            this.props.getDemographicQuestions(nextProps.demographyQuestionIds);
        }
        if (Object.keys(this.state.answeredQuestions).length === 0 && Object.keys(nextProps.demography).length) {
            this.setState({answeredQuestions: nextProps.demography});
        }
    }

    private saveDemographicQuestions = () => {
        console.log('Save demography questions');
        if (Object.keys(this.state.answeredQuestions).length > 0) {
            this.props.setDemographicQuestions({
                studentId: this.props.studentId,
                answers: this.state.answeredQuestions,
            });

            this.props.history.push(`/${this.props.match.params.slug}/journey`);
        }
    };

    private setAnswer = (result: string) => {
        this.setState({answer: result});
    };

    private acceptAnswer = () => {
        if (this.state.answer && this.state.answer.length) {
            if (this.state.activeQuestion) {
                const answeredQuestions = {...this.state.answeredQuestions};
                answeredQuestions[this.state.activeQuestion.demographyParameter] = this.state.answer;

                this.setState({
                    openModal: false,
                    answer: '',
                    answeredQuestions: answeredQuestions,
                });
            }
        }
    };

    private acceptGenderAnswer = (question: DemographicQuestionInterface, answer: string) => {
        const answeredQuestions = {...this.state.answeredQuestions};
        answeredQuestions.gender = answer;

        this.setState({
            openModal: false,
            answer: '',
            answeredQuestions: answeredQuestions,
        });
    };

    private logOut = () => {
        this.props.history.push(`/${this.props.match.params.slug}`);
        this.props.logOut();
    };

    private openModal = (question: DemographicQuestionInterface) => {
        const answer = this.state.answeredQuestions[question.demographyParameter];
        this.setState({
            openModal: !this.state.openModal,
            activeQuestion: question,
            answer: answer,
        });
    };

    private acceptIpadAnswer = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | null, value: string) => {
        if (e) {
            e.preventDefault();
        }
        if (this.state.activeQuestion) {
            const answeredQuestions = {...this.state.answeredQuestions};
            answeredQuestions[this.state.activeQuestion.demographyParameter] = value;

            this.setState({
                openModal: false,
                answer: '',
                answeredQuestions: answeredQuestions,
            });
        }
    };

    private getTruncateText = (text: string) => {
        if (this.props.width < 368) {
            return text.length > 24 ? text.substr(0, 21) + '...' : text;
        }
        return text.length > 28 ? text.substr(0, 26) + '...' : text;
    };

    private getTruncateOption = (text: string) => {
        if (this.props.width < 360) {
            return text.length > 24 ? text.substr(0, 21) + '...' : text;
        }
        return text.length > 26 ? text.substr(0, 24) + '...' : text;
    };

    private renderTitleAndGenre = (excludeGenderQuestion: DemographicQuestionInterface | undefined) => {
        if (excludeGenderQuestion) {
            return (
                <div className={styles.titleAndGenreContainer}>
                    <div>
                        <div className={styles.usertitle}>Hi, {this.props.username && this.props.username.split("+")[0]}!</div>
                        <div className={styles.subtitle}>How would you describe yourself?</div>
                    </div>
                    {excludeGenderQuestion ? (
                        <div className={styles.genderContainer}>
                            <SelectGender
                                answer={this.state.answeredQuestions.gender}
                                question={excludeGenderQuestion}
                                onChange={this.acceptGenderAnswer}
                            />
                        </div>
                    ) : null}
                </div>
            );
        }

        return null;
    };

    private renderQuestions = () => {
        if (this.props.demographicQuestions) {
            const demographyQuestions = [...this.props.demographicQuestions];
            let excludeGenderQuestion;
            for (let i = 0; i < demographyQuestions.length; i++) {
                const currentQuestion = demographyQuestions[i];
                if (currentQuestion.demographyParameter === 'gender') {
                    if (currentQuestion.options.length <= 3) {
                        excludeGenderQuestion = currentQuestion;
                        demographyQuestions.splice(i, 1);
                        break;
                    }
                }
            }

            return (
                <div className={styles.renderQuestionContainer}>
                    {this.renderTitleAndGenre(excludeGenderQuestion)}
                    <div className={styles.questionsContainer}>
                        {demographyQuestions.map((question, index) => {
                            let isAnswered = false;
                            let text = question.textEn;
                            if (this.state.answeredQuestions[question.demographyParameter]) {
                                isAnswered = true;
                                if (this.state.answeredQuestions.hasOwnProperty(question.demographyParameter)) {
                                    for (let i = 0; i < question.options.length; i++) {
                                        const questionOption = question.options[i];
                                        const answeredQuestionDemographyParameter = this.state.answeredQuestions[
                                            question.demographyParameter
                                        ];
                                        if (answeredQuestionDemographyParameter === questionOption.value) {
                                            text = questionOption.label;
                                            text = this.getTruncateText(text);
                                            break;
                                        }
                                    }
                                }
                            }
                            return (
                                <div className={styles.questionDropdownContainer} key={index}>
                                    <Question
                                        isAnswered={isAnswered}
                                        text={text}
                                        handleClick={() => this.openModal(question)}
                                    />
                                    {this.renderIpadAnswers(question.id)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return <div>Waiting for load questions</div>;
    };

    private renderAnswers = () => {
        if (this.state.activeQuestion) {
            return (
                <div className={styles.containerAnswers}>
                    <div className={styles.containerAnswers}>
                        {this.state.activeQuestion.options.map((option, index) => {
                            return (
                                <div key={`dem_${index}`}>
                                    <DemographicalRadioInput
                                        key={`dem_${index}`}
                                        text={option.label}
                                        id={option.value}
                                        value={this.state.answer}
                                        onChange={this.setAnswer}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    private renderIpadAnswers = (questionId: number) => {
        if (this.props.width >= MIN_IPAD_WIDTH && this.props.height >= MIN_IPAD_HEIGHT) {
            if (this.state.activeQuestion && this.state.openModal) {
                if (questionId === this.state.activeQuestion.id) {
                    return (
                        <div
                            className={styles.ipadAnswersClickOutsideContainer}
                            ref={(e: HTMLDivElement) => {
                                this.refClickoutsideContainer = e;
                            }}
                        >
                            <ClickOutside
                                handleFunc={() => {
                                    this.setState({
                                        openModal: false,
                                        answer: '',
                                        activeQuestion: null,
                                    });
                                }}
                            >
                                <div
                                    className={styles.ipadAnswers}
                                    ref={(element: HTMLDivElement) => this.calculateIpadDropdown(questionId, element)}
                                >
                                    <div className={styles.containerAnswers} role="listbox">
                                        {this.state.activeQuestion.options.map((option, index) => {
                                            return (
                                                <div
                                                    onClick={(event) => this.acceptIpadAnswer(event, option.value)}
                                                    key={`dem_${index}`}
                                                    tabIndex={0}
                                                    aria-pressed="false"
                                                    aria-label={`${option.label}`}
                                                    role="button"
                                                    onKeyDown={(e) =>
                                                        onKeyDown(e, () => this.acceptIpadAnswer(null, option.value))
                                                    }
                                                    onKeyUp={(e) =>
                                                        onKeyUp(e, () => this.acceptIpadAnswer(null, option.value))
                                                    }
                                                >
                                                    {this.getTruncateOption(option.label)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </ClickOutside>
                        </div>
                    );
                }
            }
        }

        return null;
    };

    private calculateIpadDropdown = (questionId: number, element: HTMLDivElement | null) => {
        // Scroll to dropdown position
        if (this.state.activeQuestion) {
            if (this.state.openModal && questionId === this.state.activeQuestion.id) {
                if (element) {
                    setTimeout(() => {
                        if (this.refClickoutsideContainer) {
                            this.refClickoutsideContainer.style.height = element.offsetHeight + 'px';
                        }
                    }, 0);
                    window.scrollTo({
                        top: element.getBoundingClientRect().top - element.offsetHeight,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    onSkip = () => {
        this.props.history.push(`/${this.props.match.params.slug}/journey`);
    };

    render() {
        let isSaveActive = !Object.keys(this.state.answeredQuestions).length;
        return (
            <div className={styles.container}>
                <div className={styles.containerContent}>
                    <div
                        className={`${styles.navigation} ${styles.login}`}
                        role="button"
                        tabIndex={0}
                        onClick={this.logOut}
                        onKeyDown={(e) => onKeyDown(e, this.logOut)}
                        onKeyUp={(e) => onKeyUp(e, this.logOut)}
                    >
                        Logout
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        className={`${styles.navigation} ${styles.skip}`}
                        onClick={this.onSkip}
                        onKeyDown={(e) => onKeyDown(e, this.onSkip)}
                        onKeyUp={(e) => onKeyUp(e, this.onSkip)}
                    >
                        Skip
                    </div>
                    <div className={styles.subContainerContent}>
                        {this.renderQuestions()}
                        <div className={styles.positionSave}>
                            <LightMainButton
                                isDefaultActive={!isSaveActive}
                                onButtonClick={this.saveDemographicQuestions}
                                text="Save"
                            />
                        </div>
                    </div>
                </div>
                {this.props.width < MIN_IPAD_WIDTH || this.props.height < MIN_IPAD_HEIGHT ? (
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.openModal}
                        closeAfterTransition
                        onClose={() => this.setState({openModal: false, answer: ''})}
                        onEscapeKeyDown={() => this.setState({openModal: false, answer: ''})}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                            classes: {root: `${styles.backdropFilter}`},
                        }}
                    >
                        <Fade in={this.state.openModal}>
                            <div className={styles.containerModal}>
                                <div className={styles.modalTitle}>
                                    <div>{this.state.activeQuestion ? this.state.activeQuestion.textEn : null}</div>
                                </div>
                                <hr className={styles.divider} />
                                {this.renderAnswers()}
                                <hr className={styles.divider} />
                                <div className={styles.containerModalButtons}>
                                    <div
                                        className={`${!this.state.answer ? styles.acceptDisabled : ''}`}
                                        onClick={() => this.acceptAnswer()}
                                    >
                                        Accept
                                    </div>
                                    <div onClick={() => this.setState({openModal: false, answer: ''})}>Cancel</div>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<{
        type: string;
        answers?: {[key: string]: string};
    }>
) => ({
    getDemographicQuestions(ids: number[]) {
        return dispatch(getQuestions('demographyQuestions', ids));
    },
    setDemographicQuestions(params: {studentId: string; answers: {[key: string]: string}}) {
        return dispatch(setDemographicQuestions(params));
    },
    logOut() {
        return dispatch(logOut());
    },
});

const mapStateToProps = function (state: RootStateInterface) {
    return {
        loginUserSuccess: state.auth.success,
        demographyQuestionIds: state.surveyTemplate.demographyQuestionIds,
        studentId: state.auth.studentId,
        username: state.auth.username,
        demographicQuestions: state.questions.demographyQuestions,
        successSetDemographicQuestions: state.demographicQuestions.successSetDemographicQuestions,
        demography: state.demographicQuestions.answers,
        width: state.window.width,
        height: state.window.height,
    };
};

const ContainerDemographicalWithRouter = withRouter(ContainerDemographical);

export default connect(mapStateToProps, mapDispatchToProps)(ContainerDemographicalWithRouter);
